import React, { useCallback, useEffect, useState } from 'react';
import styles from './css/User.module.css';
import {Link, useParams} from "react-router-dom";
import {api, projectUuid} from "../../index";

export const User = () => {
    const { userId } = useParams();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [isVerified, setIsVerified] = useState(false);

    // Обновление данных пользователя
    const updateUser = useCallback(() => {
        fetch(`${api}/user/admin/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
                firstName: firstName,
                lastName: lastName,
                middleName: middleName,
                birthDate: birthdate,
                phone: phone,
                email: email,
                isVerified: isVerified,
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    console.error('Error updating user:', data.error);
                } else {
                    // Показываем уведомление об успешном обновлении
                    alert('Данные пользователя успешно обновлены!');
                    console.log('User updated successfully:', data);
                }
            })
            .catch(err => {
                console.error('Error during update:', err);
            });
    }, [userId, firstName, lastName, middleName, birthdate, phone, email, isVerified]);


    // Получение данных пользователя
    const fetchUser = useCallback(() => {
        fetch(`${api}/user/admin/?user_id=${userId}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => {
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setMiddleName(data.middleName);
                setBirthdate(data.birthdate);
                setPhone(data.phone);
                setEmail(data.email);
                setIsVerified(data.isVerified);  // получаем статус верификации
            })
            .catch((err) => console.error('Error fetching user:', err));
    }, [userId]);

    // Загрузка данных при монтировании компонента
    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    return (
        <div className={styles.user}>
            <Link className={styles.button} to={`/admin/project/${projectUuid}/raffles/1/receipts`}>
                Назад
            </Link>

            <hr/>

            <div className={styles.id}>{userId}</div>

            <label className={styles.label} htmlFor="firstName">Имя</label>
            <input
                className={styles.input}
                type="text"
                id="firstName"
                value={firstName}
                placeholder="Введите имя"
                onChange={(e) => setFirstName(e.target.value)}
            />

            <label className={styles.label} htmlFor="lastName">Фамилия</label>
            <input
                className={styles.input}
                type="text"
                id="lastName"
                value={lastName}
                placeholder="Введите фамилию"
                onChange={(e) => setLastName(e.target.value)}
            />

            <label className={styles.label} htmlFor="middleName">Отчество</label>
            <input
                className={styles.input}
                type="text"
                id="middleName"
                value={middleName}
                placeholder="Введите отчество"
                onChange={(e) => setMiddleName(e.target.value)}
            />

            <label className={styles.label} htmlFor="birthdate">Дата рождения</label>
            <input
                className={styles.input}
                type="date"
                id="birthdate"
                value={birthdate}
                onChange={(e) => setBirthdate(e.target.value)}
            />

            <label className={styles.label} htmlFor="phone">Телефон</label>
            <input
                className={styles.input}
                type="text"
                id="phone"
                value={phone}
                placeholder="Введите номер телефона"
                onChange={(e) => setPhone(e.target.value)}
            />

            <label className={styles.label} htmlFor="email">Электронная почта</label>
            <input
                readOnly={true}
                className={styles.input}
                type="email"
                id="email"
                value={email}
                placeholder="Введите электронную почту"
                onChange={(e) => setEmail(e.target.value)}
            />

            {/* Чекбокс для верификации */}
            <label className={styles.label} htmlFor="isVerified">
                Верифицирован
            </label>
            <input
                className={styles.input}
                type="checkbox"
                id="isVerified"
                checked={isVerified}
                onChange={(e) => setIsVerified(e.target.checked)}
            />

            {/* Кнопка для обновления данных */}
            <button
                className={[styles.button, styles.buttonPrimary].join(' ')}
                type="button"
                onClick={updateUser}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000">
                    <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>
                </svg>
                <div>Обновить данные</div>
            </button>
        </div>
    );
};
