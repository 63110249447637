import React from 'react';
import styles from './css/Contacts.module.css';
import {FooterPublic} from "../shared/Footer";
import {Introduction} from "./Introduction";

export const Contacts = () => {
    return (
        <div className={styles.public}>
            <Introduction/>
            <section className={styles.contacts}>
                <div className={styles.contact}>
                    <h2 className={styles.title}>Контакты</h2>
                    <p className={styles.subtitle}>Если возникли вопросы, связанные с участием в Рекламной игре, напиши нам:
                        <a href="mailto:info@korona-promo.by">info@korona-promo.by</a>
                    </p>
                    <p>Или позвони по номеру информационной линии:
                        <a href="tel:+375445572730">+375 (44) 557 27 30</a>
                    </p>
                </div>
            </section>
            <FooterPublic/>
        </div>
    );
}