import React, {useCallback, useEffect, useState} from 'react';
import styles from "./css/Profile.module.css";
import {api} from "../../index";

export const Profile = () => {
    const [user, setUser] = useState({});

    const fetchUser = useCallback(() => {
        fetch(`${api}/user/`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => setUser(data))
            .catch((err) => console.error('Error adding project:', err));
    }, []);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    return (
        <section className={styles.profile}>
            <h1 className={styles.h1}>Мои данные</h1>
            <p className={styles.subtitle}>Если вы хотите изменить свои данные,</p>
            <p className={styles.subtitle}>обратитесь по номеру "Информационной</p>
            <p className={styles.subtitle}>линии" <a href='tel:+375445572730'>+ 375 (44) 557 27 30</a></p>

            <div className={styles.form}>
                <label htmlFor="lastName">Фамилия</label>
                <input id="lastName" type="text" placeholder="Иванов" value={user.lastName} readOnly={true}/>

                <label htmlFor="name">Имя</label>
                <input id="name" type="text" placeholder="Иван" value={user.firstName} readOnly={true}/>

                <label htmlFor="middleName">Отчество</label>
                <input id="middleName" type="text" placeholder="Иванович" value={user.middleName} readOnly={true}/>

                <label htmlFor="birthdate">Дата рождения</label>
                <input id="birthdate" type="date" value={user.birthdate} readOnly={true}/>

                <label htmlFor="phone">Телефон</label>
                <input id="phone" type="text" placeholder="" value={user.phone} readOnly={true}/>

                <label htmlFor="email">Email</label>
                <input id="email" type="text" placeholder="asdsad" value={user.email} readOnly={true}/>
            </div>
        </section>
    );
};