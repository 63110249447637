import React, { useCallback, useEffect, useState } from 'react';
import styles from './css/Winners.module.css';
import { FooterPublic } from "../shared/Footer";
import minus from "../../img/minus1.svg";
import plus from "../../img/Plus1.svg";
import { api, raffleId } from "../../index";

export const Winners = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [winners, setWinners] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRaffle, setSelectedRaffle] = useState(null);

    const fetchWinners = useCallback(() => {
        fetch(`${api}/receipts/winners/?id=${raffleId}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => setWinners(data))
            .catch((err) => console.error('Error fetching winners:', err));
    }, []);

    useEffect(() => {
        fetchWinners();
    }, [fetchWinners]);

    const toggleDetails = () => {
        setIsOpen((prev) => !prev);
    };

    const filteredWinners = winners.filter((winner) => {
        if (selectedRaffle) {
            // Дата розыгрыша, выбранная пользователем
            const raffleDate = new Date(selectedRaffle.raffle);

            // Дата выигрыша победителя, которую мы получаем от сервера
            const winnerRaffleDate = new Date(winner.raffleDate.replace(" ", "T")); // предполагается, что 'winner.raffleDate' приходит как строка

            // Сравниваем, если дата розыгрыша совпадает с датой выигрыша
            if (raffleDate.getTime() !== winnerRaffleDate.getTime()) {
                return false; // Выигрыш не совпадает с выбранным розыгрышем
            }
        }

        // Фильтрация по фамилии или игровому коду
        const searchLower = searchTerm.toLowerCase();
        const fullName = `${winner.lastName} ${winner.firstName} ${winner.middleName}`.toLowerCase();
        const receiptCode = winner.receiptCodeFormatted.toLowerCase();

        return (
            fullName.includes(searchLower) || receiptCode.includes(searchLower)
        );
    });

    const raffles = [
        {
            start: new Date('2024-11-20T00:00:00'),
            end: new Date('2024-11-26T23:59:59'),
            raffle: new Date('2024-11-29T12:30:00'),
            name: "Розыгрыш 29.11.2024"
        },
        {
            start: new Date('2024-11-27T00:00:00'),
            end: new Date('2024-12-03T23:59:59'),
            raffle: new Date('2024-12-06T12:30:00'),
            name: "Розыгрыш 06.12.2024"
        },
        {
            start: new Date('2024-12-04T00:00:00'),
            end: new Date('2024-12-10T23:59:59'),
            raffle: new Date('2024-12-13T12:30:00'),
            name: "Розыгрыш 13.12.2024"
        },
        {
            start: new Date('2024-12-11T00:00:00'),
            end: new Date('2024-12-17T23:59:59'),
            raffle: new Date('2024-12-20T12:30:00'),
            name: "Розыгрыш 20.12.2024"
        },
        {
            start: new Date('2024-12-18T00:00:00'),
            end: new Date('2024-12-24T23:59:59'),
            raffle: new Date('2024-12-27T12:30:00'),
            name: "Розыгрыш 27.12.2024"
        },
        {
            start: new Date('2024-12-25T00:00:00'),
            end: new Date('2024-12-31T23:59:59'),
            raffle: new Date('2025-01-03T12:30:00'),
            name: "Розыгрыш 03.01.2025"
        },
        {
            start: new Date('2025-01-01T00:00:00'),
            end: new Date('2025-01-07T23:59:59'),
            raffle: new Date('2025-01-10T12:30:00'),
            name: "Розыгрыш 10.01.2025"
        },
        {
            start: new Date('2025-01-08T00:00:00'),
            end: new Date('2025-01-14T23:59:59'),
            raffle: new Date('2025-01-17T12:30:00'),
            name: "Розыгрыш 17.01.2025"
        },
        {
            start: new Date('2024-11-20T00:00:00'),
            end: new Date('2025-01-14T23:59:59'),
            raffle: new Date('2025-01-17T13:00:00'),
            name: "Главный розыгрыш 17.01.2025"
        }
    ];

    return (
        <section className={styles.public}>
            <div className={styles.container}>
                <h2 className={styles.title}>Победители</h2>
                <p className={styles.subtitle}>Поздравляем победителей Рекламной игры!</p>
                <div className={styles.block}>
                    <div className={styles.draw}>
                        {(raffles ?? []).map((raffle) => (
                            <button
                                key={raffle.name}
                                onClick={() => setSelectedRaffle(raffle)}
                                className={`${styles.raffle} ${
                                    selectedRaffle?.name === raffle.name ? styles.activeDate : ''
                                }`}
                            >
                                {raffle.name}
                            </button>
                        ))}
                    </div>

                    <div className={styles.drawMobile}>
                        <p className={styles.drawTitle} onClick={toggleDetails}>
                            Выберите дату розыгрыша
                            <img
                                src={isOpen ? minus : plus}
                                alt={isOpen ? "Скрыть" : "Показать"}
                            />
                        </p>
                        {isOpen && (
                            <><div className={styles.drawSubtitle}>
                                {(raffles ?? []).map((raffle) => (
                                    <button
                                        key={raffle.name}
                                        onClick={() => setSelectedRaffle(raffle)}
                                        className={`${styles.raffle} ${
                                            selectedRaffle?.name === raffle.name ? styles.activeDate : ''
                                        }`}
                                    >
                                        {raffle.name}
                                    </button>
                                ))}
                            </div>
                            <input
                            className={styles.searchMob}
                            placeholder="Введите игровой код или фамилию"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                            </>
                        )}
                    </div>

                    <div className={styles.winners}>
                        <input
                        className={styles.searchDesc}
                            placeholder="Введите игровой код или фамилию"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <table className={styles.table}>
                            <thead>
                            <tr>
                                <th>ФИО</th>
                                <th>Игровой код</th>
                                <th>Приз</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredWinners.map((winner) => (
                                <tr key={winner.id}>
                                    <td>{winner.lastName} {winner.firstName} {winner.middleName}</td>
                                    <td>{winner.receiptCodeFormatted}</td>
                                    <td>{winner.prize}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <div className={styles.mobileTable}>
                            {filteredWinners.map((winner) => (
                                <div key={winner.id} className={styles.blockTable}>
                                    <p>ФИО</p>
                                    <span>{winner.lastName} {winner.firstName} {winner.middleName}</span>
                                    <p>Игровой код</p>
                                    <span>{winner.receiptCodeFormatted}</span>
                                    <p>Приз</p>
                                    <span>{winner.prize}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <FooterPublic />
        </section>
    );
};
