import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Admin } from "./components/admin/Admin";
import { Public } from "./components/public/Public";
import { Client } from "./components/client/Client";

export const api = "/api";
export const projectUuid = "67318d988322c";
export const raffleId = "1";

export const checkRaffleStatus = async () => {
    try {
        const res = await fetch(`${api}/raffle/?id=${raffleId}`, {
            credentials: 'include'
        });

        // Check if the response is OK (status in the range 200-299)
        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        return await res.json(); // Ensure this is returned correctly
    } catch (err) {
        console.error('Error checking authentication:', err);
        return false; // Return false in case of an error
    }
};

export const checkAuth = async () => {
    try {
        const res = await fetch(`${api}/auth/?projectUuid=${projectUuid}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Check if the response is OK (status in the range 200-299)
        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }

        return await res.json(); // Ensure this is returned correctly
    } catch (err) {
        console.error('Error checking authentication:', err);
        return false; // Return false in case of an error
    }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/client/*" element={<Client />} />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/*" element={<Public />} />
      </Routes>
    </Router>
  </React.StrictMode>,
);
reportWebVitals();