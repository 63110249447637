import React, { useCallback, useEffect, useState } from 'react';
import styles from './css/Raffles.module.css';
import { Link, useParams } from "react-router-dom";
import { api } from "../../index";

export const Raffles = () => {
    const { projectUuid } = useParams();
    const [page, setPage] = useState(0);
    const [data, setData] = useState({ raffles: [], pages: 0 });

    const fetchRaffles = useCallback(() => {
        fetch(`${api}/raffles/?projectUuid=${projectUuid}&page=${page}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => setData(data))
            .catch((err) => console.error('Error fetching raffles:', err));
    }, [projectUuid, page]);

    useEffect(() => {
        fetchRaffles();
    }, [fetchRaffles]);

    return (
        <div className={styles.raffles}>
            <Link to={`/admin/project/${projectUuid}/raffles/new`} className={styles.link}>
                <span>Создать новый розыгрыш</span>
            </Link>
            <div className={styles.table}>
                {(data.raffles ?? []).map((raffle) => (
                    <div key={raffle.id} className={styles.row}>
                        <div className={styles.name}>
                            <Link to={`/admin/project/${projectUuid}/raffles/${raffle.id}/receipts`} className={styles.link}>
                                <span>{raffle.name}</span>
                            </Link>
                        </div>
                        <div className={styles.id}>{raffle.id}</div>
                        <div className={styles.started}>{new Date(raffle.started).toLocaleDateString()}</div>
                        <div className={styles.finished}>{new Date(raffle.finished).toLocaleDateString()}</div>
                    </div>
                ))}
            </div>
            <div className={styles.pagination}>
                {Array.from({ length: data.pages }, (_, index) => (
                    <button
                        key={index}
                        className={page === index ? styles.activePage : styles.inactivePage}
                        onClick={() => setPage(index)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};
