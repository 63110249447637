import React, { useState } from 'react';
import styles from './css/NewPrize.module.css';
import { api } from "../../index";
import { useParams } from "react-router-dom";

export const NewPrize = () => {
    const { projectUuid } = useParams();
    const [prizeName, setPrizeName] = useState('');

    // Функция для добавления нового приза
    const addPrize = () => {
        if (prizeName.length === 0) {
            alert('Введите название приза');
            return;
        }

        fetch(`${api}/prizes/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                projectUuid: projectUuid,
                name: prizeName,
            })
        })
            .then((res) => res.json())
            .then(() => {
                window.history.back();
            })
            .catch((err) => {
                console.error('Error adding prize:', err);
            });
    };

    return (
        <div className={styles.prize}>
            <h1 className={styles.h1}>Новый приз</h1>
            <div className={styles.form}>
                <label className={styles.label} htmlFor="name">Название приза</label>
                <input
                    className={styles.input}
                    type="text"
                    id="name"
                    value={prizeName}
                    placeholder="Введите название приза"
                    onChange={(e) => setPrizeName(e.target.value)}
                />
                <div className={styles.controls}>
                    <button
                        className={styles.button}
                        type="button"
                        onClick={() => window.history.back()}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000">
                            <path
                                d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
                            />
                        </svg>
                        <div>Отменить</div>
                    </button>
                    <button
                        className={[styles.button, styles.buttonPrimary].join(' ')}
                        type="button"
                        onClick={addPrize}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000">
                            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>
                        </svg>
                        <div>Добавить приз</div>
                    </button>
                </div>
            </div>
        </div>
    );
};
