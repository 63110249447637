import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styles from './css/Project.module.css';
import {api} from "../../index";
import {Raffles} from "./Raffles";
import {Users} from "./Users";

export const Project = () => {
    const {uuid} = useParams();
    const [project, setProject] = useState({});
    const [selectedTab, setSelectedTab] = useState(0);

    const fetchProject = useCallback(() => {
        fetch(`${api}/project/?uuid=${uuid}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => setProject(data))
            .catch((err) => console.error('Error adding project:', err));
    }, [uuid]);

    useEffect(() => {
        fetchProject();
    }, [fetchProject]);

    return (
        <div className={styles.project}>
            <h1 className={styles.h1}>{project.name}</h1>

            <div className={styles.tabs}>
                <button onClick={() => setSelectedTab(0)}
                        className={[styles.tab, selectedTab === 0 ? styles.tabActive : ""].join(' ')}>Розыгрыши
                </button>
                <button onClick={() => setSelectedTab(1)}
                        className={[styles.tab, selectedTab === 1 ? styles.tabActive : ""].join(' ')}>Пользователи
                </button>
            </div>

            {selectedTab === 0 ? (
                <Raffles/>
            ) : (
                <Users/>
            )}

            <br/>

            <h1>{project.name}</h1>
            <h3>{project.id}</h3>
            <p>{project.uuid}</p>
            <p>{project.created}</p>
        </div>
    );
};
