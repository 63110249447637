import React, {useCallback, useEffect, useState} from 'react';
import styles from './css/Raffle.module.css';
import {Link, useParams} from "react-router-dom";
import {api} from "../../index";
import DeclineModal from "../shared/DeclineModal";
import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import AcceptModal from "../shared/AcceptModal";
import PrizeModal from "../shared/PrizeModal";
import debounce from 'lodash.debounce';

export const Raffle = () => {
    const {raffleId, projectUuid} = useParams();
    // eslint-disable-next-line
    const [raffle, setRaffle] = useState({});
    const [receipts, setReceipts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isAcceptConfirmOpen, setIsAcceptConfirmOpen] = useState(false);
    const [isDeclineConfirmOpen, setIsDeclineConfirmOpen] = useState(false);
    const [isPrizeConfirmOpen, setIsPrizeConfirmOpen] = useState(false);
    const [selectedImg, setSelectedImg] = useState(null);
    const [declineReason, setDeclineReason] = useState(null);
    const [codeError, setCodeError] = useState("");
    const [status, setStatus] = useState(0);
    const [prizes, setPrizes] = useState([]);
    const [raffles, setRaffles] = useState([]);

    const [selectedPrize, setSelectedPrize] = useState(null);
    const [setSelectedRaffle] = useState({});

    // eslint-disable-next-line
    const [prize, setPrize] = useState(null);
    const [code, setCode] = useState(null);
    const [receiptSum, setReceiptSum] = useState(null);

    const [prizeCode, setPrizeCode] = useState("");
    const [selectedReceipt, setSelectedReceipt] = useState({});
    const [selectedDate, setSelectedDate] = useState({});

    const fetchRaffles = useCallback(() => {
        fetch(`${api}/raffles/all/?projectUuid=${projectUuid}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((raffles) => setRaffles(raffles))
            .catch((err) => console.error('Error fetching raffles:', err));
    }, [projectUuid]);

    const fetchPrizes = useCallback(() => {
        fetch(`${api}/prizes/?projectUuid=${projectUuid}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((prizes) => setPrizes(prizes))
            .catch((err) => console.error('Error fetching raffles:', err));
    }, [projectUuid]);

    const fetchRaffle = useCallback(() => {
        fetch(`${api}/raffle/?id=${raffleId}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => setRaffle(data))
            .catch((err) => console.error('Error fetching raffle:', err));
    }, [raffleId]);

    // eslint-disable-next-line
    const fetchReceipts = useCallback(
        debounce(() => {
            fetch(`${api}/receipts/?id=${raffleId}&search=${searchTerm}&isAccepted=${status}`, {
                credentials: 'include',
            })
                .then((res) => res.json())
                .then((data) => setReceipts(data))
                .catch((err) => console.error('Error fetching receipts:', err));
        }, 200),
        [searchTerm, raffleId, status]
    );

    useEffect(() => {
        Fancybox.bind("[data-fancybox]", {});
        fetchPrizes();
        fetchRaffle();
        fetchRaffles();
        fetchReceipts(); // Fetch receipts on initial load
        console.log('selectedReceipt updated:', selectedReceipt);
    }, [fetchPrizes, fetchRaffle, fetchRaffles, fetchReceipts, searchTerm, selectedReceipt]);

    const handlePrizeButtonClick = (receipt) => {
        // Выводим receipt перед обновлением состояния
        console.log('Clicked receipt:', receipt);
        setSelectedReceipt(receipt);
        console.log('Selected Receipt:', selectedReceipt);
        setIsPrizeConfirmOpen(true);
    };

    return (
        <div className={styles.raffle}>
            <h1 className={styles.h1}>Модерация чеков</h1>
            <div className={styles.controls}>
                <Link className={styles.export} to={`/admin/project/${projectUuid}/raffles/${raffleId}/export`}>
                    Экспорт
                </Link>
                <Link className={styles.export} to={`/admin/project/${projectUuid}/raffles/${raffleId}/prizes`}>
                    Призы
                </Link>
                <Link className={styles.export} to={`/`}>
                    Перейти на сайт
                </Link>
            </div>

            <div className={styles.searchContainer}>
                <input
                    type="text"
                    className={styles.searchInput}
                    placeholder="Поиск по имени, фамилии, коду..."
                    value={searchTerm}
                    onChange={(e) => {
                        const value = e.target.value;
                        setSearchTerm(value); // Update search term state
                        fetchReceipts(); // Trigger search
                    }}
                />
                <button
                    type="button"
                    className={[styles.moderation, status === 0 ? styles.active : ''].join(' ')}
                    onClick={() => {
                        setStatus(0);
                        fetchReceipts();
                    }}>
                    Модерация
                </button>

                <button
                    type="button"
                    className={[styles.accept, status === 1 ? styles.active : ''].join(' ')}
                    onClick={() => {
                        setStatus(1);
                        fetchReceipts();
                    }}>
                    Одобренные
                </button>

                <button
                    type="button"
                    className={[styles.reject, status === -1 ? styles.active : ''].join(' ')}
                    onClick={() => {
                        setStatus(-1);
                        fetchReceipts();
                    }}>
                    Отклоненные
                </button>
            </div>

            <div className={styles.table}>
                {receipts.length === 0 && (
                    <p>Чеки не найдены</p>
                )}
                {(receipts ?? []).map((receipt) => (
                    <div className={styles.receiptCol} key={receipt.id}>
                        <div className={[styles.row, `row-status${receipt.isAccepted}`].join(' ')}>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                {receipt.image.split(";").map((image, index) => (
                                    <a
                                        key={index}  // или используйте уникальный идентификатор, если он есть
                                        className={styles.receipt}
                                        href={`${api}/receipts/${image}`}
                                        data-fancybox={`gallery-${receipt.id}`}
                                        data-caption={receipt.code ?? '-'}
                                    >
                                        <img alt="" src={`${api}/receipts/${image}`} />
                                    </a>
                                ))}
                            </div>
                            <div className={styles.user}>
                                <Link
                                    to={`/admin/project/${projectUuid}/users/${receipt.userId}`}>{receipt.lastName} {receipt.firstName} {receipt.middleName}</Link>
                            </div>
                            <div className={styles.code}>{receipt.code ?? '-'}</div>
                            <div className={styles.receiptCodesCount}>{receipt.receiptCodes.length ?? '-'}</div>
                            <div className={[
                                styles.isAccepted,
                                receipt.isAccepted === 1 ? styles.verified :
                                    receipt.isAccepted === 0 ? styles.pending : styles.unverified
                            ].join(' ')}>
                                {receipt.isAccepted === 1 ? "Да" :
                                    receipt.isAccepted === 0 ? "Ожидает" : "Нет"}
                            </div>

                            <div className={styles.uploaded}>
                                {receipt.created ? new Date(receipt.created).toLocaleString() : '-'}
                            </div>
                            <div className={styles.acceptedAt}>
                                {receipt.acceptedAt ? new Date(receipt.acceptedAt).toLocaleString() : '-'}
                            </div>

                            {/* Prize Button */}
                            {receipt.isAccepted === 1 && (
                                <button
                                    className={styles.button}
                                    onClick={() => handlePrizeButtonClick(receipt)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                         width="24px"
                                         fill={receipt.prizeId ? '#369' : '#bbb'}>
                                        <path
                                            d="M160-80v-440H80v-240h208q-5-9-6.5-19t-1.5-21q0-50 35-85t85-35q23 0 43 8.5t37 23.5q17-16 37-24t43-8q50 0 85 35t35 85q0 11-2 20.5t-6 19.5h208v240h-80v440H160Zm400-760q-17 0-28.5 11.5T520-800q0 17 11.5 28.5T560-760q17 0 28.5-11.5T600-800q0-17-11.5-28.5T560-840Zm-200 40q0 17 11.5 28.5T400-760q17 0 28.5-11.5T440-800q0-17-11.5-28.5T400-840q-17 0-28.5 11.5T360-800ZM160-680v80h280v-80H160Zm280 520v-360H240v360h200Zm80 0h200v-360H520v360Zm280-440v-80H520v80h280Z"/>
                                    </svg>
                                </button>
                            )}

                            {/* Accept Button */}
                            {receipt.isAccepted < 1 && (
                                <button
                                    className={styles.button}
                                    onClick={() => {
                                        setSelectedReceipt(receipt);
                                        setSelectedImg(`${api}/receipts/${receipt.image}`);
                                        setIsAcceptConfirmOpen(true);
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                         width="24px" fill="green">
                                        <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>
                                    </svg>
                                </button>
                            )}

                            {/* Decline Button */}
                            {receipt.isAccepted === 0 && (
                                <button
                                    className={styles.button}
                                    onClick={() => {
                                        setSelectedReceipt(receipt);
                                        setSelectedImg(`${api}/receipts/${receipt.image}`);
                                        setIsDeclineConfirmOpen(true);
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                         width="24px" fill="red">
                                        <path
                                            d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                                    </svg>
                                </button>
                            )}
                        </div>
                        {receipt.isAccepted === -1 && (
                            <div className={styles.reason}>{receipt.declineReason ?? '-'}</div>
                        )}
                        {receipt.isAccepted === 1 && (
                            <ReceiptItem key={receipt.id} receipt={receipt}/>
                        )}
                    </div>
                ))}
            </div>

            <PrizeModal
                receipt={selectedReceipt}
                isOpen={isPrizeConfirmOpen}
                setSelectedCode={setPrizeCode}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedCode={prizeCode}
                onClose={() => {
                    setIsPrizeConfirmOpen(false);
                    setSelectedReceipt(null);
                }}
                onConfirm={() => {
                    console.log({
                        prize: prize,
                        prizeId: selectedPrize,
                        raffleDate: selectedDate,
                        receiptId: selectedReceipt.id,
                        receiptCodeId: prizeCode
                    })

                    fetch(`${api}/receipt/prize/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            prize: prize,
                            prizeId: selectedPrize,
                            raffleDate: selectedDate,
                            receiptId: selectedReceipt.id,
                            receiptCodeId: prizeCode
                        }),
                    })
                        .then(response => response.json())
                        .then(result => {
                            fetchReceipts(); // Refresh list after prize update
                        })
                        .catch(error => console.error('Error:', error));

                    setIsPrizeConfirmOpen(false);
                }}
                setPrize={setPrize}
                prizes={prizes}
                raffles={raffles}
                setSelectedPrize={setSelectedPrize}
                setSelectedRaffle={setSelectedRaffle}
            />

            <AcceptModal
                img={selectedImg}
                error={codeError}
                isOpen={isAcceptConfirmOpen}
                onClose={() => {
                    setIsAcceptConfirmOpen(false);
                    setSelectedReceipt(null);
                    setCode(null);
                }}
                onConfirm={() => {
                    setCodeError('');
                    if (code.length === 0) {
                        setCodeError('Укажите код чека')
                        return
                    }
                    fetch(`${api}/receipt/accept/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            receiptId: selectedReceipt.id,
                            sum: receiptSum,
                            code: code,
                        }),
                    })
                        .then(response => response.json())
                        .then(result => {
                            if (result.error) {
                                // Если ошибка связана с дубликатом
                                if (result.error.includes('Duplicate entry')) {
                                    setCodeError('Чек уже зарегистрирован');
                                } else {
                                    setCodeError(result.error);
                                }
                                return;
                            }
                            fetchReceipts();
                            setIsAcceptConfirmOpen(false);
                        })
                        .catch(error => {
                            console.error('Ошибка:', error);
                        });
                }}
                openDeclineModal={() => setIsDeclineConfirmOpen(true)}
                code={code}
                setCode={setCode}
                receiptSum={receiptSum}
                setReceiptSum={setReceiptSum}
            />

            <DeclineModal
                img={selectedImg}
                isOpen={isDeclineConfirmOpen}
                onClose={() => {
                    setIsDeclineConfirmOpen(false);
                    setSelectedReceipt(null);
                    setDeclineReason(null);
                }}
                onConfirm={() => {
                    fetch(`${api}/receipt/decline/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            receiptId: selectedReceipt.id,
                            declineReason: declineReason,
                        }),
                    })
                        .then(response => response.json())
                        .then(result => {
                            fetchReceipts(); // Refresh after declining receipt
                        })
                        .catch(error => console.error('Error:', error));

                    setIsDeclineConfirmOpen(false);
                }}
                openAcceptModal={() => setIsAcceptConfirmOpen(true)}
                declineReason={declineReason}
                setDeclineReason={setDeclineReason}
            />
        </div>
    );
};

const ReceiptItem = ({receipt}) => {
    const [isOpen, setIsOpen] = useState(false); // Локальное состояние для открытия/закрытия списка кодов

    return (
        <div className={styles.receiptCodes}>
            {receipt.receiptCodes.length > 10 && (
                <button onClick={() => setIsOpen(!isOpen)} style={{borderRadius: '4px'}} type="button">
                    {isOpen ? 'Свернуть' : 'Развернуть'}
                </button>
            )}
            {(receipt.receiptCodes ?? [])
                .sort((a, b) => a.id - b.id)
                .slice(0, isOpen ? receipt.receiptCodes.length : 10)
                .map((receiptCode) => (
                    <span className={styles.codeBadge} key={receiptCode.id}>
                        {receiptCode.id}
                    </span>
                ))}
        </div>
    );
};