import React from 'react';
import styles from './css/Rules.module.css';
import image10 from '../../img/basket.svg';
import image11 from '../../img/list.svg';
import image12 from '../../img/prize.svg';

export const Rules = () => {
    return (
        <section id="rules" className={styles.rules}>
            <div className={styles.container}>
                <div className={styles.startBlock}>
                    <h2 className={styles.title}>Как участвовать в рекламной игре?</h2>
                    <div className={styles.buttons}>
                        <a
                            href="/rules.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.rulesButton}
                        >
                            Правила
                        </a>
                        <a href="#form" className={styles.participateButton}>Участвовать</a>
                    </div>
                </div>
                <div className={styles.cards}>
                    <div className={styles.card}>
                        <div className={styles.cardImage}>
                            <img src={image10} alt="basket"/>
                        </div>
                        <h3>ПОКУПАЙ</h3>
                        <p>продукцию ТМ Milka <br/> от 2 BYN в одном чеке</p>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.cardImage}>
                            <img src={image11} alt="basket"/>
                        </div>
                        <h3>РЕГИСТРИРУЙ</h3>
                        <p>чеки на сайте <br/> www.korona-promo.by</p>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.cardImage}>
                            <img src={image12} alt="basket"/>
                        </div>
                        <h3>УЧАСТВУЙ</h3>
                        <p>в розыгрыше <br/> призов</p>
                    </div>
                </div>
            </div>
        </section>
    );
};