import React from 'react';
import styles from './css/Cookie.module.css';
import {FooterPublic} from "../shared/Footer";
import {Introduction} from "./Introduction";

export const Cookie = () => {
    return (
        <div className={styles.public}>
            <Introduction/>
            <div className={styles.container1}>
                <div className={styles.text}>
                    <h1>ПОЛИТИКА В ОТНОШЕНИИ ОБРАБОТКИ COOKIE</h1>
                    <div className={styles.textBlock}>
                        <h2>ГЛАВА 1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                        <p>1. Настоящая политика в отношении обработки cookie (далее – Политика) разработана в
                            соответствии с
                            положениями Закона Республики Беларусь от 10.11.2008 N 455-З «Об информации, информатизации
                            и защите
                            информации», Закона Республики Беларусь от 7 мая 2021 г. № 99–З «О защите персональных
                            данных» (далее
                            совместно – Законы) и иных актов законодательства Республики Беларусь, регулирующих
                            отношения, связанные
                            с обработкой персональных данных.</p>
                        <p>2. В Политике используются специальные термины в значениях, установленных Законами, а также
                            термины,
                            имеющие следующие значения:</p>
                        <p>2.1. Компания – производственное унитарное предприятие «Кока-Кола Бевриджиз Белоруссия»,
                            зарегистрированное в Едином государственном регистре юридических лиц и индивидуальных
                            предпринимателей
                            под номером 100373962, с местонахождением по адресу: деревня Колядичи,147/2, Сеницкий
                            сельсовет, Минский
                            район, Минская область.</p>
                        <p>2.2. Пользователь – субъект персональных данных, который посетил Сайт или совершил на нем
                            какие-либо
                            действие посредством подключения его технического устройства к Сайту через сеть глобальную
                            компьютерную
                            сеть Интернет.</p>
                        <p>2.3. Сайт – информационный ресурс Компании в глобальной компьютерной сети Интернет,
                            адресуемый
                            посредством доменного имени coca-cola-promo.by.</p>
                        <p>2.4. Сookie – текстовые файлы, сохраняемые (размещаемые) на технических устройствах
                            Пользователей
                            (например, на компьютере, смартфоне или планшете) при их посещении Сайта для отражения
                            совершенных им
                            действий. Файлы сookie имеют множество различных функций, например, позволяют Пользователю
                            не вводить
                            заново ранее заполненные (выбранные) им параметры доступа к Сайту при повторном
                            посещении.</p>
                        <p>3. Политика определяет порядок обработки Компанией сведений из cookie, устанавливает
                            перечень, цели и
                            порядок использования Компанией таких сведений, а также совокупность прав Пользователей по
                            отношению к
                            Компании, связанных с обработкой Компанией таких сведений.</p>
                        <p>4. Политика распространяется на все действия Компании, относящиеся к обработке сведений из
                            cookie, а
                            именно: сбор, систематизацию, хранение, изменение, использование, блокирование,
                            распространение,
                            предоставление, удаление. Сведения из cookie обрабатываются Компанией исключительно с
                            использованием
                            средств автоматизации.</p>
                        <p>5. Компания не использует cookie для идентификации конкретного Пользователя и не передает
                            сведения из
                            cookie третьим лицам.</p>

                        <h2>ГЛАВА 2. ИСПОЛЬЗУЕМЫЕ КОМПАНИЕЙ COOKIE</h2>
                        <p>6. Компания использует cookie, которые можно разделить на следующие типы:</p>
                        <p>6.1. В зависимости от цели использования:</p>
                        <p>6.1.1. Необходимые cookie. Такие cookie необходимы для обеспечения корректного
                            функционирования Сайта.
                            Как правило, они активируются только в ответ на действия Пользователей, аналогичные запросу
                            услуг, такие
                            как настройка уровня конфиденциальности, вход в систему или заполнение форм. Пользователи
                            могут
                            настроить браузер таким образом, чтобы он блокировал эти cookie или уведомлял их об их
                            использовании, но
                            в таком случае возможно, что некоторые разделы Сайта не будут работать.</p>
                        <p>6.1.2. Эксплуатационные cookie. Такие cookie являются вспомогательными и позволяют Компании
                            подсчитывать
                            количество посещений Сайта и источников трафика, чтобы оценивать и улучшать работу Сайта.
                            Благодаря им
                            Компания знает, какие страницы Сайта являются наиболее и наименее популярными, и видит,
                            каким образом
                            Пользователи перемещаются по Сайту. Все данные, собираемые Компанией при помощи этих cookie,
                            группируются в статистику, а значит, являются анонимными. Если Пользователь не одобрит
                            использование
                            этих cookie, у Компании не будет данных о посещении Пользователем Сайта.</p>
                        <p>6.2. В зависимости от сроков хранения в браузере устройства Пользователя:</p>
                        <p>6.2.1. Сеансовые cookie. Такие cookie являются временными и хранятся до момента закрытия
                            браузера
                            Пользователем или окончания сеанса использования Сайта.</p>
                        <p>6.2.2. Постоянные cookie. Такие cookie подлежат хранению в памяти устройства Пользователя в
                            течение
                            времени, указанного в параметрах cookie, или до момента их непосредственного удаления самим
                            Пользователем.</p>
                        <p>7. Срок хранения cookie на устройстве Пользователя определяется исходя из того, относятся ли
                            обрабатываемые cookie к Сеансовым или Постоянным.</p>
                        <p>7.1. Сеансовые cookie подлежат хранению до момента закрытия браузера Пользователем или
                            окончания сеанса
                            использования Сайта.</p>
                        <p>7.2. Постоянные cookie подлежат хранению не более 2 (двух) лет с момента последнего посещения
                            Пользователем Сайта.</p>
                        <p>8. Компания, выступая в качестве оператора, обрабатывает сведения из cookie в течение сроков,
                            указанных в
                            таблицах 1 и 2.</p>

                        <p className={styles.tabsTitle}>Таблица 1. Строго необходимые cookie:</p>
                        <div className={styles.table}>
                            <div className={styles.tableTitle}>
                                <p>Подгруппа файлов cookie</p>
                                <p>Файлы cookie</p>
                                <p>Категории данных</p>
                                <p>Срок использования</p>
                            </div>

                            <div className={styles.tableSubtitle}>
                                <div className={styles.mobileTitle}>
                                    <p>Подгруппа файлов cookie</p>
                                    <span>coca-cola-promo.by</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Файлы cookie</p>
                                    <span>PHPSESSID, undefined.ACCEPTED, undefined.FUNCTIONA, undefined. PERFORMANCE, undefined. STRICTLY_NECESSARY, undefined.TARGETING, cc_cookie</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Категории данных</p>
                                    <span>Данные аутентификации, уникальный идентификатор Пользователя, сведения о браузере и (или) используемом Пользователем устройстве, языковые настройки, настройки файлов cookie.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Срок использования</p>
                                    <span>Сеанс, 1 год, 1 год, 1 год, 1 год, 1 год, 182 дня</span>
                                </div>
                                <span>coca-cola-promo.by</span>
                                <span>PHPSESSID, undefined.ACCEPTED, undefined.FUNCTIONA, undefined. PERFORMANCE, undefined. STRICTLY_NECESSARY, undefined.TARGETING, cc_cookie</span>
                                <span>Данные аутентификации, уникальный идентификатор Пользователя, сведения о браузере и (или) используемом Пользователем устройстве, языковые настройки, настройки файлов cookie.</span>
                                <span>Сеанс, 1 год, 1 год, 1 год, 1 год, 1 год, 182 дня</span>
                            </div>
                        </div>

                        <p className={styles.tabsTitle}>Таблица 2. Эксплуатационные cookie:</p>
                        <div className={styles.table}>
                            <div className={styles.tableTitle}>
                                <p>Подгруппа файлов cookie</p>
                                <p>Файлы cookie</p>
                                <p>Категории данных</p>
                                <p>Срок использования</p>
                            </div>

                            <div className={styles.tableSubtitle}>
                                <div className={styles.mobileTitle}>
                                    <p>Подгруппа файлов cookie</p>
                                    <span>coca-cola-promo.by</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Файлы cookie</p>
                                    <span>_ym_metrika_enabled, _ym_isad, _ym_uid, _ym_d, gdpr, is_gdpr, is_gdpr_b, yabs-sid, _ym_debug, _ym_visorc_*, _ym_hostIndex, i, yandexuid, yuidss, ymex, usst, skid, yandex_gid, yashr, yp, ys, bh, _ga, _ga_*, _gid, _gat</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Категории данных</p>
                                    <span>Данные об использовании Сайта, в частности, число посещений Сайта Пользователями, сведения о браузерах и (или) используемых Пользователями устройствах.</span>
                                </div>
                                <div className={styles.mobileTitle}>
                                    <p>Срок использования</p>
                                    <span>60 минут, 2 дня, 1 год, 1 год, До 2 лет, До 2 лет, До 2 лет, Сеанс, Сеанс, 30 минут, 1 сутки, 1 год, 1 год, 1 год, 1 год, 1 год, 309 дней, 10 дней, 313 дней, 380 дней, Сеанс, 1 год, 2 года, 2 года, 24 часа, 1 минута</span>
                                </div>
                                <span>coca-cola-promo.by</span>
                                <span>_ym_metrika_enabled, _ym_isad, _ym_uid, _ym_d, gdpr, is_gdpr, is_gdpr_b, yabs-sid, _ym_debug, _ym_visorc_*, _ym_hostIndex, i, yandexuid, yuidss, ymex, usst, skid, yandex_gid, yashr, yp, ys, bh, _ga, _ga_*, _gid, _gat</span>
                                <span>Данные об использовании Сайта, в частности, число посещений Сайта Пользователями, сведения о браузерах и (или) используемых Пользователями устройствах.</span>
                                <span>60 минут, 2 дня, 1 год, 1 год, До 2 лет, До 2 лет, До 2 лет, Сеанс, Сеанс, 30 минут, 1 сутки, 1 год, 1 год, 1 год, 1 год, 1 год, 309 дней, 10 дней, 313 дней, 380 дней, Сеанс, 1 год, 2 года, 2 года, 24 часа, 1 минута</span>
                            </div>
                        </div>

                        <h2>ГЛАВА 3. СОГЛАСИЕ ПОЛЬЗОВАТЕЛЯ НА ОБРАБОТКУ COOKIE</h2>
                        <p>9. При первом посещении Сайта Компания предлагает Пользователю дать согласие на использование
                            всех типов
                            cookie либо отказаться от использования некоторых из них посредством нажатия соответствующих
                            кнопок на
                            всплывающем информационном баннере.</p>
                        <p>10. При нажатии кнопки «Подтвердить все» Пользователь дает согласие на использование всех
                            типов cookie,
                            используемых Компанией. При нажатии кнопки «Отклонить все» Пользователь отказывает Компании
                            в
                            возможности использовать эксплуатационные cookie. При нажатии кнопки «Изменить параметры»
                            Пользователь
                            может изменить свой первоначальный выбор посредством нажатия на «бегунок» в строке
                            «Эксплуатационные
                            файлы cookie» и нажатия кнопки «Сохранить параметры» либо нажатия одной из кнопок
                            «Подтвердить все» или
                            «Отклонить все», которые также активны в баннере «Центр настроек конфиденциальности». При
                            этом для
                            полноценной реализации права на отказ от обработки файлов cookie Пользователю необходимо
                            отключить
                            использование cookie путем изменения настроек браузера. Компания прекращает сбор информации
                            с помощью
                            cookie после их деактивации.</p>
                        <p>11. Поскольку Компания может обеспечить полноценное и корректное функционирование Сайта, в
                            том числе
                            безопасность его использования, только с использованием необходимых (функциональных) cookie,
                            данный тип
                            cookie не подлежит отключению.</p>
                        <p>12. Сайт сохраняет выбор Пользователя о настройках файлов cookie в течение 1 (одного) года.
                            По окончании
                            этого периода Сайт вновь запросит согласие Пользователя посредством вывода всплывающего
                            информационного
                            баннера.</p>
                        <p>13. Большинство браузеров изначально настроены на прием cookie. Таким образом, для
                            полноценной реализации
                            права на отказ от обработки файлов cookie Пользователь может отключить использование файлов
                            cookie путем
                            изменения настроек своего браузера.</p>
                        <p>14. Пользователь также может удалить ранее сохраненные файлы сookie, выбрав соответствующую
                            опцию в
                            истории браузера. Кроме того, некоторые браузеры позволяют посещать сайты в режиме
                            «инкогнито», чтобы
                            ограничить хранимый на компьютере объем информации и автоматически удалять некоторые типы
                            файлов cookie.
                            О возможностях управления настройками других браузеров Пользователь может получить уточнения
                            в
                            документации их разработчиков.</p>

                        <h2>ГЛАВА 4. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
                        <p>15. Вопросы, касающиеся обработки персональных данных, не урегулированные Политикой, подлежат
                            рассмотрению в рамках иных локальных правовых актов Компании, в частности, Политики в
                            отношении
                            обработки персональных данных, а также законодательства Республики Беларусь.</p>
                        <p>16. В случае, если какое-либо положение Политики признается противоречащим законодательству
                            Республики
                            Беларусь, остальные положения, соответствующие законодательству Республики Беларусь,
                            остаются в силе и
                            являются действительными, а любое недействительное положение будет считаться удаленным
                            (измененным) в
                            той мере, в какой это необходимо для обеспечения его соответствия законодательству
                            Республики
                            Беларусь.</p>
                        <p>17. Компания имеет право по своему усмотрению изменять и (или) дополнять условия настоящей
                            Политики без
                            предварительного и (или) последующего уведомления Пользователей. Действующая редакция
                            Политики постоянно
                            доступна на Сайте.</p>
                    </div>
                </div>
            </div>
            <FooterPublic/>
        </div>
    );
}