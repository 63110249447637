import React from 'react';
import styles from './css/Footer.module.css';
import image28 from "../../img/korona.svg";
import image29 from "../../img/milko.svg";
import {Link} from "react-router-dom";

export const FooterPublic = () => {
    return (
        <section id="footer" className="footer">
            <div className={styles.container}>
                <div className="footer-container c h sb">
                    <div className="v footer-logo">
                        <a href="/" className="f1">
                            <img className="image-footer" src={image28} alt="Cola"/>
                        </a>
                        <a href="/" className="f1">
                            <img className="image-footer milka-icon" src={image29} alt="Cola"/>
                        </a>
                    </div>
                    <div className="v footer-contacts">
                        <h3>Контакты</h3>
                        <div className="information v sb">
                            <a href="tel:+375445572730">+375 (44) 557 27 30</a>
                            <p>ПН-ПТ с 10:00 до 17:00</p>
                            <p>СБ с 10:00 до 13:00</p>
                        </div>
                    </div>
                    <div className="v conditions">
                        <h3><Link to={`/terms`}>Условия использования</Link></h3>
                        <h3><Link to={`/privacy`}>Положение о конфиденциальности</Link></h3>
                        <h3><Link to={`/cookie`}>Политика «cookie»</Link></h3>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="info v c">
                    <h2>Рекламная игра «Волна нежности!»</h2>
                    <p>Сроки проведения рекламной игры: с 20 ноября 2024 г. по 17 марта 2025 г. (включая период
                        розыгрышей и выдачи призов).</p>
                    <p>Полные правила рекламной игры на сайте www.korona-promo.by. Организатор: ЧРУП “Промофреш”, УНП 691757897.  </p>
                    <p>Свидетельство государственной регистрации №4485, выданное МАРТ РБ от 01.11.2024 г.</p>
                </div>
            </div>
        </section>
    );
};
