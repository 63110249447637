import React from 'react';
import styles from './css/Public.module.css';
import {HeaderPublic} from "../shared/Header";
import {FooterPublic} from "../shared/Footer";
import {Introduction} from "./Introduction";
import {FAQ} from "./FAQ";
import {Rules} from "./Rules";
import {Prizes} from "./Prizes";
import {Form} from "./Form";
import {Route, Routes} from "react-router-dom";
import {Winners} from "./Winners";
import {Contacts} from "./Contacts";
import {Terms} from "./Terms";
import {Privacy} from "./Privacy";
import {Cookie} from "./Cookie";
import {Auth} from "../shared/Auth";
import {CookieNotification} from "./Notification";

export const Public = () => {
    return (
        <div className={styles.public}>
            <HeaderPublic/>
            <Routes>
                <Route path="/" element={<Page/>}/>
                <Route path="/winners" element={<Winners/>}/>
                <Route path="/contacts" element={<Contacts/>}/>
                <Route path="/terms" element={<Terms/>}/>
                <Route path="/privacy" element={<Privacy/>}/>
                <Route path="/cookie" element={<Cookie/>}/>
                <Route path="/auth" element={<Auth/>}/>
            </Routes>
        </div>
    );
};

const Page = () => {
    return (
        <div className={styles.public}>
            <Introduction/>
            <Rules/>
            <Prizes/>
            <Form />
            <FAQ/>
            <CookieNotification/>
            <FooterPublic/>
        </div>
    );
}