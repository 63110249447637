import React, { useCallback, useEffect, useState } from 'react';
import styles from './css/Prizes.module.css'; // Создай отдельный файл для стилей, если его нет
import { Link, useParams } from "react-router-dom";
import { api } from "../../index";

export const Prizes = () => {
    const { projectUuid, raffleId } = useParams(); // Get raffleId from route parameters
    const [prizes, setPrizes] = useState([]);
    const [newPrizeName, setNewPrizeName] = useState(''); // Для нового приза

    // Функция для получения списка призов
    const fetchPrizes = useCallback(() => {
        fetch(`${api}/prizes/?projectUuid=${projectUuid}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((prizes) => setPrizes(prizes))
            .catch((err) => console.error('Error fetching prizes:', err));
    }, [projectUuid]);

    // Функция для добавления нового приза
    const handleAddPrize = () => {
        if (!newPrizeName) {
            return alert('Введите название приза.');
        }

        fetch(`${api}/prizes/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ name: newPrizeName, projectUuid }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.message) {
                    alert('Приз успешно добавлен');
                    setNewPrizeName(''); // Очистить поле ввода
                    fetchPrizes(); // Обновить список
                } else {
                    alert('Ошибка при добавлении приза: ' + data.error);
                }
            })
            .catch((err) => console.error('Error adding prize:', err));
    };

    // Вызов fetchRaffles при изменении page
    useEffect(() => {
        fetchPrizes();
    }, [fetchPrizes]);

    return (
        <div className={styles.prizes}>
            <div className={styles.prizesHead}>
                <Link className={styles.back} to={`/admin/project/${projectUuid}/raffles/${raffleId}/receipts`}>
                    Вернуться к розыгрышу
                </Link>
                <h1>Призы</h1>
            </div>
            <div className={styles.newPrize}>
                <input
                    type="text"
                    placeholder="Введите название нового приза"
                    value={newPrizeName}
                    onChange={(e) => setNewPrizeName(e.target.value)}
                />
                <button onClick={handleAddPrize} className={styles.addButton}>
                    Добавить приз
                </button>
            </div>

            <div className={styles.table}>
                {(prizes ?? []).map((prize) => (
                    <div key={prize.id} className={styles.row}>
                        <div className={styles.id}>{prize.id}</div>
                        <div className={styles.name}>{prize.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
