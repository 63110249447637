import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import styles from './css/Notification.module.css';

export const CookieNotification = () => {
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        // Таймер на 5 секунд для отображения уведомления
        const timer = setTimeout(() => {
            setShowNotification(true);
        }, 3000);

        // Очистка таймера при размонтировании компонента
        return () => clearTimeout(timer);
    }, []);

    return (
        showNotification && (
            <div className={styles.title}>
                <h3>
                    Продолжая пользоваться этим сайтом, не изменив настройки своего браузера, вы соглашаетесь с тем,
                    что мы можем использовать файлы cookie.{' '}
                    <Link to="/terms" className={styles.link}>Политика использования</Link>
                </h3>
                <button onClick={() => setShowNotification(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                         fill="#5f6368"><path
                        d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                </button>
            </div>
        )
    );
};
