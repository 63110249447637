import React, { useCallback, useEffect, useState } from 'react';
import styles from './css/Users.module.css';
import { Link, useParams } from "react-router-dom";
import { api } from "../../index";

export const Users = () => {
    const { projectUuid } = useParams();
    const [page, setPage] = useState(0);
    const [data, setData] = useState([]);

    const fetchUsers = useCallback(() => {
        fetch(`${api}/users/?projectUuid=${projectUuid}&page=${page}`, {
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data);
            })
            .catch((err) => console.error('Error fetching users:', err));
    }, [projectUuid, page]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return (
        <div className={styles.users}>
            <div className={styles.table}>
                {(data.users ?? []).map((user) => (
                    <div key={user.id} className={styles.row}>
                        <div className={styles.id}>{user.id}</div>
                        <div className={styles.name}>
                            <Link to={`/admin/project/${projectUuid}/users/${user.id}`} className={styles.link}>
                                <span>{user.lastName} {user.firstName} {user.middleName}</span>
                            </Link>
                        </div>
                        <div className={styles.email}>{user.email}</div>
                        <div className={styles.birthDate}>{user.birthDate}</div>
                        <div className={[styles.isVerified, user.isVerified ? styles.verified : styles.unverified].join(' ')}>
                            {user.isVerified ? "Да" : "Нет"}
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.pagination}>
                {Array.from({ length: data.pages }, (_, index) => (
                    <button
                        key={index}
                        className={page === index ? styles.activePage : styles.inactivePage}
                        onClick={() => setPage(index)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};