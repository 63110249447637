import React, {useEffect, useState} from 'react';
import image1 from "../../img/korona.svg";
import image2 from "../../img/milko.svg";
import styles from './css/Header.module.css';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {HashLink} from 'react-router-hash-link';
import image21 from "../../img/background-mobile.jpg";
import {api, checkAuth, checkRaffleStatus, projectUuid} from "../../index";
import InputMask from 'react-input-mask';
import {FaEye, FaEyeSlash} from "react-icons/fa";

const AuthStage = {
    SIGN_IN: 'SIGN_IN',
    SIGN_UP: 'SIGN_UP',
    AGREEMENT: 'AGREEMENT',
    VERIFICATION: 'VERIFICATION',
    VERIFICATION_COMPLETED: 'VERIFICATION_COMPLETED',
    RECOVERY: 'RECOVERY',
    SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
    PASSWORD_RECOVERED: 'PASSWORD_RECOVERED'
};

export const HeaderAdmin = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthentication = async () => {
            const auth = await checkAuth();

            console.log(auth)
            if (!auth.isAuthenticated || !auth.isAdmin) {
                navigate('/');
            }
        };

        checkAuthentication();
    }, [navigate]);

    return (<></>);
};

export const HeaderPublic = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [raffleStatus, setRaffleStatus] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [authScene, setScene] = useState(AuthStage.SIGN_IN);

    // eslint-disable-next-line
    const [loginError, setLoginError] = useState("");
    // eslint-disable-next-line
    const [signupError, setSignUpError] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatedPassword, setRepeatedPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isRepeatedPasswordVisible, setRepeatedPasswordVisible] = useState(false);
    const [recoveryPassword, setRecoveryPassword] = useState("");
    const [recoveryRepeatedPassword, setRecoveryRepeatedPassword] = useState("");

    // eslint-disable-next-line
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    // eslint-disable-next-line
    const [message, setMessage] = useState('');

    // eslint-disable-next-line
    const [phone, setPhone] = useState("");

    const [rulesAgreement, setRulesAgreement] = useState(false);
    const [nonPartnerAgreement, setNonPartnerAgreement] = useState(false);

    // eslint-disable-next-line
    const [errors, setErrors] = useState({});
    const [recoveryToken, setRecoveryToken] = useState("");

    useEffect(() => {
        // Сброс всех полей формы и ошибок при смене сцены
        setEmail("");
        setPassword("");
        setRepeatedPassword("");
        setFirstName("");
        setLastName("");
        setMiddleName("");
        setPhone("");
        setBirthdate("");
        setErrors({});
        setSignUpError(""); // Если ошибка регистрации есть
        setLoginError("");  // Если ошибка входа есть
    }, [authScene]);

    useEffect(() => {
        const menuLinks = document.querySelectorAll('.header-list a');
        menuLinks.forEach(link => {
            link.addEventListener('click', () => setIsBurgerOpen(false));
        });
        return () => {
            menuLinks.forEach(link => {
                link.removeEventListener('click', () => setIsBurgerOpen(false));
            });
        };
    }, []);

    useEffect(() => {
        // Функция для получения параметра по имени из строки запроса
        const getQueryParam = (name) => {
            const urlParams = new URLSearchParams(location.search);
            return urlParams.get(name);
        };

        // Получаем параметр 'isVerified'
        const isVerified = getQueryParam('isVerified');
        const isSetNewPassword = getQueryParam('isSetNewPassword');
        const recoveryToken = getQueryParam('recoveryToken');

        setRecoveryToken(recoveryToken)

        // Если параметр isVerified = 'true', вызываем нужную функцию
        if (isVerified === 'true') {
            setIsOpen(true);
            setScene(AuthStage.VERIFICATION_COMPLETED)
        }

        // Если параметр isVerified = 'true', вызываем нужную функцию
        if (isSetNewPassword === 'true') {
            setIsOpen(true);
            setScene(AuthStage.SET_NEW_PASSWORD)
        }

        checkRaffleStatus().then((raffle) => {
            setRaffleStatus(raffle.isFinished);

            checkAuth().then((auth) => {
                if (auth.isAuthenticated) {
                    setIsLoggedIn(true);
                }
            });
        });
    }, [location, navigate, setScene]);

    const validateFields = () => {
        let fieldErrors = {};
        if (!email) fieldErrors.email = 'Требуется ввести электронную почту';
        if (!password) fieldErrors.password = 'Требуется ввести пароль';
        if (authScene === AuthStage.SIGN_UP) {
            if (!firstName) fieldErrors.firstName = 'Требуется ввести имя';
            if (!lastName) fieldErrors.lastName = 'Требуется ввести фамилию';
            if (!birthdate) fieldErrors.birthdate = 'Требуется указать дату рождения';
            if (!phone) fieldErrors.phone = 'Требуется указать телефон';
            if (password !== repeatedPassword) fieldErrors.repeatedPassword = 'Пароли не совпадают';
            if (!rulesAgreement) fieldErrors.rulesAgreement = 'Необходимо согласиться с правилами';
            if (!nonPartnerAgreement) fieldErrors.nonPartnerAgreement = 'Необходимо подтвердить отсутствие партнёрства';
        }
        setErrors(fieldErrors);
        return Object.keys(fieldErrors).length === 0;
    };

    const validateEmail = async () => {
        if (!email) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Требуется ввести электронную почту',
            }));
            return;
        }

        const emailIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        if (!emailIsValid) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Неверный формат электронной почты',
            }));
            return;
        }

        try {
            const response = await fetch(`${api}/auth/check-email/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email}),
            });

            const data = await response.json();
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: data.exists ? 'Этот Email уже зарегистрирован' : '',
            }));
        } catch (error) {
            console.error('Ошибка при проверке существования пользователя:', error);
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Ошибка при проверке электронной почты',
            }));
        }
    };

    const validatePassword = () => {
        let passwordErrors = {};

        if (!password) {
            passwordErrors.password = 'Требуется ввести пароль';
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            ...passwordErrors,
        }));
    };

    const validateConfirmPassword = () => {
        if (password !== repeatedPassword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                repeatedPassword: 'Пароли не совпадают',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                repeatedPassword: '',
            }));
        }
    };

    const validatePhone = () => {
        const phoneRequirements = /^\+375 \d{2}-\d{3}-\d{2}-\d{2}$/;
        if (!phone) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: 'Требуется указать телефон',
            }));
        } else if (!phoneRequirements.test(phone)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: 'Неверный формат телефона, используйте +375 XX-XXX-XX-XX',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phone: '',
            }));
        }
    };

    const signIn = () => {
        if (validateFields()) {
            fetch(`${api}/auth/signin/?projectUuid=${projectUuid}`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({email: email, password: password}),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.error) {
                        setLoginError(data.error); // Установите сообщение об ошибке
                    } else if (data.user.id) {
                        if (!data.user.isVerified) {
                            setScene(AuthStage.VERIFICATION);
                            return;
                        }
                        setIsLoggedIn(true);
                        setIsOpen(false);
                        navigate("/client");
                    }
                })
                .catch((err) => {
                    console.error('Error during sign in:', err);
                });
        }
    };

    const signUp = () => {
        if (validateFields()) {
            fetch(`${api}/auth/signup/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    projectUuid: projectUuid,
                    email: email,
                    password: password,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    middleName: middleName,
                    birthDate: birthdate
                }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.error) {
                        if (data.error.includes('Duplicate entry')) {
                            setSignUpError('Этот Email уже зарегистрирован');
                        } else {
                            setSignUpError(data.error);
                        }
                    } else {
                        setScene(AuthStage.VERIFICATION);
                    }
                })
                .catch(err => {
                    console.error('Error during sign up:', err);
                });
        }
    };

    const handlePasswordRecovery = async () => {
        try {
            const response = await fetch(`${api}/auth/recovery/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({projectUuid, email})
            });
            const data = await response.json();

            if (data.success) {
                setScene(AuthStage.VERIFICATION)
            }
        } catch (error) {
            setErrors({email: 'Ошибка при отправке запроса'});
        }
    };

    const handleSetNewPassword = async () => {
        try {
            const response = await fetch(`${api}/auth/set-new-password/`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({recoveryCode: recoveryToken, recoveryPassword})
            });
            const data = await response.json();

            if (data.success) {
                setScene(AuthStage.PASSWORD_RECOVERED)
                // setMessage(data.success);
            }
        } catch (error) {
            setErrors({email: 'Ошибка при отправке запроса'});
        }
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };
    const toggleRepeatedPasswordVisibility = () => {
        setRepeatedPasswordVisible(!isRepeatedPasswordVisible);
    };


    return (
        <header className={styles.headerPublic}>
            {isOpen && (
                <div className={`modalOverlay ${isOpen ? "show" : ""}`}
                     onClick={() => setIsOpen(false)}>
                    <div className={`modal ${isOpen ? "show" : ""}`}
                         onClick={(e) => e.stopPropagation()}
                    >
                        {authScene === AuthStage.SIGN_IN && (
                            <>
                                <h2 className={styles.h2}>Вход в личный кабинет</h2>
                                <div className={styles.subtitle}>
                                    Для загрузки чека зарегистрируйтесь или войдите в свой личный
                                    кабинет
                                </div>

                                <input
                                    className={errors.email ? styles.error : ""}
                                    type="text"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && (
                                    <div className={styles.errorMsg}>{errors.email}</div>
                                )}

                                <div className={styles.passwordContainer}>
                                    <input
                                        className={errors.password ? styles.error : ""}
                                        type={isPasswordVisible ? "text" : "password"}
                                        placeholder="Пароль"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className={styles.eyeButton}
                                    >
                                        {isPasswordVisible ? <FaEyeSlash/> : <FaEye/>}
                                    </button>
                                </div>
                                {errors.password && (
                                    <div className={styles.errorMsg}>{errors.password}</div>
                                )}

                                <div className={styles.checkContainer}>
                                    <label className={styles.check}>
                                        <input type="checkbox"/>
                                        Запомнить меня
                                    </label>
                                    <span onClick={() => setScene(AuthStage.RECOVERY)}>
                    Забыли пароль?
                  </span>
                                </div>

                                {loginError && <div className={styles.error}>{loginError}</div>}

                                <button onClick={signIn} className={styles.darkButton}>
                                    Вход
                                </button>

                                {!raffleStatus ? (
                                    <button
                                        className={styles.lightButton}
                                        onClick={() => setScene(AuthStage.AGREEMENT)}
                                    >
                                        Регистрация
                                    </button>
                                ) : (
                                    <label className={styles.regClosed}>
                                        Регистрация чеков на розыгрыш окончена. Вы сможете
                                        зарегистрироваться при проведении следующего розыгрыша
                                    </label>
                                )}
                            </>
                        )}

                        {authScene === AuthStage.AGREEMENT && (
                            <>
                                <h2 className={styles.h2}>
                                    Согласие на обработку персональных данных
                                </h2>

                                <div className={styles.textBlock}>
                                    <p>
                                        Для участия в рекламной игре «Волна нежности!», зарегистрированной Министерством
                                        антимонопольного регулирования и торговли Республики Беларусь 01.11.2024г. под
                                        номером 4485 (далее – Рекламная игра), Вам необходимо предоставить согласие на
                                        обработку персональных данных путем проставления соответствующей отметки внизу
                                        данной формы. Перед этим Вам необходимо ознакомиться со следующей информацией.
                                    </p>
                                    <h2>
                                        1. Что такое «персональные данные» и «обработка персональных
                                        данных»?
                                    </h2>
                                    <p>
                                        Персональные данные – любые сведения, по которым можно
                                        идентифицировать физическое лицо (установить его личность).
                                        Обработка персональных данных – любое действие или
                                        совокупность действий, совершаемые с персональными данными,
                                        включая сбор, систематизацию, хранение, изменение,
                                        использование, обезличивание, блокирование, распространение,
                                        предоставление, удаление персональных данных.
                                    </p>

                                    <h2>
                                        2. Какие персональные данные необходимы для участия в
                                        Рекламной игре?
                                    </h2>
                                    <p>
                                        Для участия в Рекламной игре Вам необходимо зарегистрироваться на интернет-сайте
                                        korona-promo.by (далее – Сайт) путем внесения в соответствующие поля формы
                                        регистрации следующих персональных данных:
                                    </p>

                                    <ul>
                                        <li>фамилия, имя, отчество (при наличии);</li>
                                        <li>дата рождения;</li>
                                        <li>
                                            абонентский номер мобильного телефона белорусского
                                            оператора сотовой связи в международном формате;
                                        </li>
                                        <li>адрес электронной почты;</li>
                                        <li>игровые коды.</li>
                                    </ul>

                                    <h2>
                                        3. Какая цель обработки персональных данных на основании
                                        Вашего согласия?
                                    </h2>
                                    <p>
                                        Обеспечение Вашего участия в Рекламной игре в соответствии с
                                        правилами Рекламной игры.
                                    </p>

                                    <h2>
                                        4. Кто будет обрабатывать персональные данные на основании
                                        Вашего согласия?
                                    </h2>
                                    <p>
                                        Владельцем Сайта, на котором Вы регистрируетесь, и, следовательно, юридическим
                                        лицом, организующим и осуществляющим обработку Ваших персональных данных
                                        совместно с другими юридическими лицами является Частное рекламное унитарное
                                        предприятие «Промофреш», УНП 691757897, местонахождение: г. Минск, ул.
                                        Интернациональная, 25а, оф. 216 (далее «Оператор»). Оператор осуществляет
                                        обработку Ваших персональных данных с привлечением следующих уполномоченных лиц:
                                    </p>
                                    <ul>
                                        <li>
                                            ООО «Надежные программы» местонахождение: ул. Аранская, 8, блок 1, 4 этаж,
                                            220006, Минск; УНП 100160363), оказывающее Оператору на основании договора
                                            услуги по предоставление в пользование информационно-технических ресурсов, а
                                            также услуги технической и криптографической защите информации, необходимые
                                            для работы Сайта.
                                        </li>
                                    </ul>

                                    <h2>
                                        5. Как долго будут храниться персональные данные на
                                        основании Вашего согласия?
                                    </h2>
                                    <p>
                                        В случае предоставления Вами согласия на обработку указанных в пункте 2
                                        персональных данных они будут обрабатываться Оператором с момента их регистрации
                                        на Сайте до окончания проведения Рекламной игры (по 17.03.2025г.).
                                    </p>
                                    <p>
                                        Обращаем внимание, что Организатор в силу требований Положения о проведении
                                        рекламных игр на территории Республики Беларусь, утвержденного Указом Президента
                                        Республики Беларусь от 30.01.2003 № 51, после окончания Рекламной игры обязан
                                        хранить все материалы по Рекламной игре в течение 3 (трех) лет со дня ее
                                        окончания.
                                    </p>

                                    <h2>
                                        6. Какие действия будут совершаться с персональными данными?
                                    </h2>
                                    <p>
                                        Оператор осуществляет с персональными данными следующие действия: сбор,
                                        систематизация, хранение, копирование, уточнение (обновление, изменение),
                                        извлечение, использование, передача (распространение, предоставление),
                                        блокирование, уничтожение (удаление) как с использованием средств автоматизации,
                                        так и без использования таковых.
                                    </p>

                                    <h2>
                                        7. Какие права Вы имеете как субъект персональных данных?
                                    </h2>
                                    <h3 className={styles.subtitle}>Право</h3>
                                    <p>
                                        1. Право на получение информации об обработке персональных
                                        данных.
                                    </p>
                                    <h3 className={styles.subtitle}>СОДЕРЖАНИЕ</h3>
                                    <p>
                                        Субъект персональных данных вправе получить информацию о том, какие его
                                        персональные данные, для чего, на каком основании и каким образом Оператор
                                        собирает и использует, в том числе информацию об Операторе и ее уполномоченных
                                        лицах, о сроке согласия на обработку персональных данных.
                                    </p>
                                    <h3 className={styles.subtitle}>ПОРЯДОК РЕАЛИЗАЦИИ</h3>
                                    <p>
                                        В течение 5 (пяти) рабочих дней после получения заявления субъекта персональных
                                        данных Оператор предоставляет субъекту персональных данных запрашиваемую
                                        информацию или уведомление о причинах отказа в ее предоставлении в письменном
                                        виде.
                                    </p>
                                    <h3 className={styles.subtitle}>ПРАВО</h3>
                                    <h2>
                                        2. Право на получение информации о предоставлении
                                        персональных данных третьим лицам.
                                    </h2>
                                    <h3 className={styles.subtitle}>СОДЕРЖАНИЕ</h3>
                                    <p>
                                        Субъект персональных данных вправе получать информацию о том, какие третьи лица
                                        и на каком основании имеют доступ к его персональным данным. Один раз в
                                        календарный год такая информация предоставляется бесплатно.
                                    </p>
                                    <h3 className={styles.subtitle}>ПОРЯДОК РЕАЛИЗАЦИИ</h3>
                                    <p>
                                        В течение 15 (пятнадцати) календарных дней после получения заявления субъекта
                                        персональных данных Оператор предоставляет субъекту персональных данных
                                        запрашиваемую информацию или уведомление о причинах отказа в ее предоставлении в
                                        письменном виде.
                                    </p>
                                    <h3 className={styles.subtitle}>ПРАВО</h3>
                                    <h2>3. Право на внесение изменений в персональные данные.</h2>
                                    <h3>СОДЕРЖАНИЕ</h3>
                                    <p>
                                        Оператор не проверяет достоверность персональных данных, предоставляемых
                                        субъектом персональных данных. Тем не менее, в любой момент субъект персональных
                                        данных вправе требовать от Оператора внести изменения в его персональные данные
                                        в случаях, если они являются неполными, неточными или утратили актуальность. К
                                        заявлению субъекта персональных данных следует приложить документы,
                                        подтверждающие необходимость внесения изменений в персональные данные.
                                    </p>
                                    <h3 className={styles.subtitle}>ПОРЯДОК РЕАЛИЗАЦИИ</h3>
                                    <p>
                                        В течение 15 (пятнадцати) календарных дней после получения
                                        заявления субъекта персональных данных Оператор внесет
                                        соответствующие изменения в персональные данные субъекта
                                        персональных данных.
                                    </p>
                                    <h3 className={styles.subtitle}>ПРАВО</h3>
                                    <h2>
                                        4. Право на прекращение обработки персональных данных или их
                                        удаление.
                                    </h2>
                                    <h3 className={styles.subtitle}>СОДЕРЖАНИЕ</h3>
                                    <p>
                                        Субъект персональных данных вправе требовать от Оператора
                                        бесплатного прекращения обработки его персональных данных,
                                        включая их удаления при отсутствии (утрате) оснований для
                                        обработки таких данных.
                                    </p>
                                    <h3 className={styles.subtitle}>ПОРЯДОК РЕАЛИЗАЦИИ</h3>
                                    <p>
                                        В течение 15 (пятнадцати) календарных дней после получения заявления субъекта
                                        персональных данных Оператор прекратит обработку персональных данных субъекта
                                        персональных данных за исключением случаев, когда Оператор вправе продолжить
                                        обработку персональных данных при наличии оснований, установленных
                                        законодательством.
                                    </p>
                                    <h3 className={styles.subtitle}>ПРАВО</h3>
                                    <h2>
                                        5. Право на отзыв согласия на обработку персональных данных
                                    </h2>
                                    <h3 className={styles.subtitle}>СОДЕРЖАНИЕ</h3>
                                    <p>
                                        Если согласие субъекта персональных данных является правовым основанием для
                                        обработки Оператором его персональных данным, субъект персональных данных может
                                        в любое время отозвать его. Субъект персональных данных может отозвать согласие
                                        без объяснения причин. Это не повлияет на законность основания обработки
                                        персональных данных субъекта персональных данных до момента отзыва Согласия.
                                    </p>
                                    <h3 className={styles.subtitle}>ПОРЯДОК РЕАЛИЗАЦИИ</h3>
                                    <p>
                                        В течение 15 (пятнадцати) календарных дней после получения заявления субъекта
                                        персональных данных или после удаления субъектом персональных данных своего
                                        личного кабинета на Сайте Оператор прекратит обработку персональных данных
                                        субъекта персональных данных, осуществит их удаление и уведомит субъекта
                                        персональных данных об этом. Исключение составляют случаи, когда Оператор или
                                        иной оператор вправе продолжить обработку персональных данных при наличии
                                        оснований, установленных законодательством.
                                    </p>
                                    <h3 className={styles.subtitle}>ПРАВО</h3>
                                    <h2>6. Право на обжалование наших действий</h2>
                                    <h3 className={styles.subtitle}>СОДЕРЖАНИЕ</h3>
                                    <p>
                                        Если субъект персональных данных считает, что действия Оператора по обработке
                                        его персональных данных нарушают законодательство или права субъекта
                                        персональных данных, субъект персональных данных может подать жалобу в
                                        уполномоченный орган.
                                    </p>
                                    <h3 className={styles.subtitle}>ПОРЯДОК РЕАЛИЗАЦИИ</h3>
                                    <p>
                                        Жалоба подается в Национальный центр по защите персональных данных Республики
                                        Беларусь в электронной или письменной форме. Срок рассмотрения жалобы – 1 (один)
                                        месяц со дня, следующего за днем ее регистрации.
                                    </p>
                                    <p>
                                        Для реализации своих прав, указанных в настоящем пункте, субъект персональных
                                        данных подает (направляет) Оператору заявление в письменной форме по почтовому
                                        адресу Оператора: 220030, г. Минск, ул. Интернациональная, 25а, оф. 216. Такое
                                        заявление должно содержать: фамилию, собственное имя, отчество (если таковое
                                        имеется) субъекта персональных данных; адрес места жительства (места пребывания)
                                        субъекта персональных данных; дату рождения субъекта персональных данных;
                                        изложение сути требований субъекта персональных данных; личную подпись субъекта
                                        персональных данных.
                                    </p>
                                    <p>
                                        При этом право отзыва ранее предоставленного согласия на обработку персональных
                                        данных может быть реализовано в электронной форме посредством удаления субъектом
                                        персональных данных своего личного кабинета на Сайте.
                                    </p>
                                    <h2>
                                        Отзыв согласия на обработку персональных данных повлечет за собой невозможность
                                        дальнейшего участия субъекта персональных данных в Рекламной игре.
                                    </h2>
                                    <p>
                                        Полный текст Политики в отношении обработки персональных
                                        данных, полученных посредством Сайта, размещен на Сайте.
                                    </p>
                                </div>

                                <button
                                    className={styles.darkButton}
                                    onClick={() => setScene(AuthStage.SIGN_UP)}
                                >
                                    Я согласен
                                </button>
                            </>
                        )}

                        {authScene === AuthStage.SIGN_UP && (<>
                            <h2 className={styles.h2}>Заполните форму регистрации</h2>
                            <div className={styles.subtitle}>Для загрузки чека зарегистрируйтесь или войдите в свой
                                личный
                                кабинет
                            </div>

                            <input
                                className={errors.lastName ? styles.error : ''}
                                type="text"
                                placeholder="Фамилия"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            {errors.lastName && <div className={styles.errorMsg}>{errors.lastName}</div>}

                            <input
                                className={errors.firstName ? styles.error : ''}
                                type="text"
                                placeholder="Имя"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            {errors.firstName && <div className={styles.errorMsg}>{errors.firstName}</div>}

                            <input type="text" placeholder="Отчество (при наличии)"
                                   value={middleName}
                                   onChange={(e) => setMiddleName(e.target.value)}/>

                            <InputMask
                                mask="+375 99-999-99-99"
                                className={errors.phone ? styles.error : ''}
                                type="text"
                                placeholder="Телефон"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                onBlur={validatePhone}
                            />
                            {errors.phone && <div className={styles.errorMsg}>{errors.phone}</div>}

                            <div className={styles.bithLabel}>
                                <label htmlFor="birthdate">Дата рождения</label>
                                <input
                                    className={errors.birthdate ? styles.error : ''}
                                    type="date"
                                    value={birthdate}
                                    onChange={(e) => setBirthdate(e.target.value)}
                                />
                                {errors.birthdate && <div className={styles.errorMsg}>{errors.birthdate}</div>}
                            </div>

                            <input
                                className={errors.email ? styles.error : ''}
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={() => {
                                    validateEmail();
                                }}
                            />
                            {errors.email && <div className={styles.errorMsg}>{errors.email}</div>}

                            <div className={styles.passwordContainer}>
                                <input
                                    className={errors.password ? styles.error : ""}
                                    type={isPasswordVisible ? "text" : "password"}
                                    placeholder="Пароль"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onBlur={validatePassword}
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className={styles.eyeButton}
                                >
                                    {isPasswordVisible ? <FaEyeSlash/> : <FaEye/>}
                                </button>
                            </div>
                            {errors.password && (
                                <div className={styles.errorMsg}>{errors.password}</div>
                            )}

                            <div className={styles.subtitlePass}>Обязательно использование хотя бы одной большой буквы,
                                цифры, специального символа. Длина пароля: не менее 8 символов.
                            </div>

                            <div className={styles.passwordContainer}>
                        <input
                             className={errors.repeatedPassword ? styles.error : ''}
                             type={isRepeatedPasswordVisible ? "text" : "password"}
                             placeholder="Подтверждение пароля"
                            value={repeatedPassword}
                            onChange={(e) => {
                                setRepeatedPassword(e.target.value);
                                validateConfirmPassword();
                            }}
                            onBlur={validateConfirmPassword}
                        />
                        <button
                            type="button"
                            onClick={toggleRepeatedPasswordVisibility}
                            className={styles.eyeButton}
                        >
                            {isRepeatedPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                    {errors.repeatedPassword && <div className={styles.errorMsg}>{errors.repeatedPassword}</div>}

                            <label className={styles.checkRules} htmlFor="rulesAgreement">
                                <input
                                    type="checkbox"
                                    id="rulesAgreement"
                                    checked={rulesAgreement}
                                    onChange={(e) => setRulesAgreement(e.target.checked)}
                                />
                                Я ознакомлен и согласен с Правилами рекламной игры и Правилами пользования сайтом
                            </label>

                            <label className={styles.checkRules} htmlFor="nonPartnerAgreement">
                                <input
                                    type="checkbox"
                                    id="nonPartnerAgreement"
                                    checked={nonPartnerAgreement}
                                    onChange={(e) => setNonPartnerAgreement(e.target.checked)}
                                />
                                Я подтверждаю, что не состою в трудовых отношениях с Организатором и не занимаю
                                руководящих должностей в организации, являющейся заинтересованным лицом
                            </label>

                            {signupError && <div className={styles.error}>{signupError}</div>}

                            <button
                                className={(rulesAgreement > 0 && nonPartnerAgreement > 0) ? styles.regActive : styles.regInactive}
                                disabled={!(rulesAgreement > 0 && nonPartnerAgreement > 0)}
                                onClick={() => {
                                    signUp();
                                }}
                            >
                                Зарегистрироваться
                            </button>
                        </>)}

                        {authScene === AuthStage.VERIFICATION && (
                            <>
                                <h2 className={styles.h2}>
                                    Письмо для подтверждения отправлено на почту
                                </h2>
                                <button
                                    className={styles.darkButton}
                                    onClick={() => {
                                        setScene(AuthStage.SIGN_IN);
                                    }}
                                >
                                    Войти
                                </button>
                            </>
                        )}

                        {authScene === AuthStage.VERIFICATION_COMPLETED && (
                            <>
                                <h2 className={styles.h2}>Аккаунт подтвержден</h2>
                                <button
                                    className={styles.darkButton}
                                    onClick={() => {
                                        setScene(AuthStage.SIGN_IN);
                                    }}
                                >
                                    Войти
                                </button>
                            </>
                        )}

                        {authScene === AuthStage.SET_NEW_PASSWORD && (
                            <>
                                <h2 className={styles.h2}>Новый пароль</h2>
                                <div className={styles.subtitle}>
                                    Введите новый пароль
                                </div>
                                <input
                                    type="password"
                                    placeholder="Пароль"
                                    value={recoveryPassword}
                                    onChange={(e) => setRecoveryPassword(e.target.value)}
                                />
                                <input
                                    type="password"
                                    placeholder="Повторите пароль"
                                    value={recoveryRepeatedPassword}
                                    onChange={(e) => setRecoveryRepeatedPassword(e.target.value)}
                                />
                                <button
                                    className={styles.darkButton}
                                    onClick={handleSetNewPassword}
                                >
                                    Сохранить
                                </button>
                            </>
                        )}

                        {authScene === AuthStage.PASSWORD_RECOVERED && (
                            <>
                                <h2 className={styles.h2}>Пароль изменен</h2>
                                <button
                                    className={styles.darkButton}
                                    onClick={() => {
                                        setScene(AuthStage.SIGN_IN);
                                    }}
                                >
                                    Войти
                                </button>
                            </>
                        )}

                        {authScene === AuthStage.RECOVERY && (
                            <>
                                <h2 className={styles.h2}>Восстановление аккаунта</h2>
                                <div className={styles.subtitle}>
                                    Чтобы получить письмо восстановления, введите свой email, на который зарегистрирован
                                    аккаунт
                                </div>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <button
                                    className={styles.darkButton}
                                    onClick={handlePasswordRecovery}
                                >
                                    Отправить ссылку для восстановления
                                </button>
                                {errors.email && <div className="errorMsg">{errors.email}</div>}
                                {message && <div className="message">{message}</div>}
                            </>
                        )}

                        <button
                            style={{
                                background: "none",
                                position: "absolute",
                                right: "12px",
                                top: "12px",
                            }}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="48px"
                                viewBox="0 -960 960 960"
                                width="48px"
                                fill="#777"
                            >
                                <path
                                    d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            )}

            <div className={styles.container}>
                <a href="/">
                    <div className="logo h c">
                        <img className="crown" src={image1} alt="logo-crown"/>
                    </div>
                </a>
                <div className="right-bar h c">
                    <nav className={styles.nav}>
                        <a
                            href="/rules.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                        >
                            Правила
                        </a>
                        <HashLink to="/#questions" className="link">
                            Вопросы
                        </HashLink>
                        <Link to={`/winners`} className="link">
                            Победители
                        </Link>
                        <HashLink to={`/#footer`} className="link">
                            Контакты
                        </HashLink>
                    </nav>
                    <div className="contacts">
                        <a href="tel:+375445572730" className="contact">
                            + 375 (44) 557 27 30
                        </a>
                    </div>

                    {isLoggedIn ? (
                        <Link to="/client">
                            <button
                                className="user"
                                onClick={() => {
                                    setIsOpen(!isOpen);
                                    setScene(AuthStage.SIGN_IN);
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 -960 960 960"
                                    width="24px"
                                    fill="#363140"
                                >
                                    <path
                                        d="M80-160v-112q0-33 17-62t47-44q51-26 115-44t141-18q30 0 58.5 3t55.5 9l-70 70q-11-2-21.5-2H400q-71 0-127.5 17T180-306q-9 5-14.5 14t-5.5 20v32h250l80 80H80Zm542 16L484-282l56-56 82 82 202-202 56 56-258 258ZM400-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm10 240Zm-10-320q33 0 56.5-23.5T480-640q0-33-23.5-56.5T400-720q-33 0-56.5 23.5T320-640q0 33 23.5 56.5T400-560Zm0-80Z"/>
                                </svg>
                            </button>
                        </Link>
                    ) : (
                        <button
                            className="user"
                            onClick={() => {
                                setIsOpen(!isOpen);
                                setScene(AuthStage.SIGN_IN);
                            }}
                        >
                            <svg
                                className="_red-login"
                                width="18"
                                height="18"
                                viewBox="0 0 21 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.64844 21V19.75C1.64844 18.6009 1.87476 17.4631 2.31449 16.4015C2.75422 15.3399 3.39874 14.3753 4.21125 13.5628C5.02377 12.7503 5.98836 12.1058 7.04996 11.6661C8.11156 11.2263 9.24937 11 10.3984 11C11.5475 11 12.6853 11.2263 13.7469 11.6661C14.8085 12.1058 15.7731 12.7503 16.5856 13.5628C17.3981 14.3753 18.0427 15.3399 18.4824 16.4015C18.9221 17.4631 19.1484 18.6009 19.1484 19.75V21"
                                    stroke="#363140"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                                <path
                                    d="M10.3984 11C11.7245 11 12.9963 10.4732 13.934 9.53553C14.8717 8.59785 15.3984 7.32608 15.3984 6C15.3984 4.67392 14.8717 3.40215 13.934 2.46447C12.9963 1.52678 11.7245 1 10.3984 1C9.07236 1 7.80059 1.52678 6.8629 2.46447C5.92522 3.40215 5.39844 4.67392 5.39844 6C5.39844 7.32608 5.92522 8.59785 6.8629 9.53553C7.80059 10.4732 9.07236 11 10.3984 11V11Z"
                                    stroke="#363140"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                            </svg>
                        </button>
                    )}

                    <a href="/">
                        <img className="milko" src={image2} alt="logo-milka"/>
                    </a>

                    <input
                        className={styles.burgerToggle}
                        id="burger-toggle"
                        type="checkbox"
                        checked={isMenuOpen}
                        onChange={toggleMenu}
                    />
                    <label className="burger-label" htmlFor="burger-toggle">
                        <span></span>
                    </label>
                    <ul className="header-list" style={{backgroundImage: `url(${image21})`}}>
                        <li>
                            <a
                                href="/rules.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="top"
                                onClick={closeMenu}
                            >
                                Правила
                            </a>
                        </li>
                        <li>
                            <HashLink to="/#questions" className="top" onClick={closeMenu}>Вопросы</HashLink>
                        </li>
                        <li>
                            <Link to={`/winners`} className="top" onClick={closeMenu}>Победители</Link>
                        </li>
                        <li>
                            <HashLink to={`/#footer`} className="top" onClick={closeMenu}>Контакты</HashLink>
                        </li>
                    </ul>

                    <div className="modal" id="loginModal">
                        <div className="modal-form v c">
                            <h2>Вход в личный кабинет</h2>
                            <p>
                                Для загрузки чека зарегистрируйтесь или войдите в свой личный
                                кабинет
                            </p>
                            <input type="email" name="email" placeholder="Email"/>
                            <input type="password" name="password" placeholder="Пароль"/>
                            <div className="check-container h sb c">
                                <label className="check">
                                    <input type="checkbox" className="check-field"/>
                                    Запомнить меня
                                </label>
                                <span>Забыли пароль?</span>
                            </div>
                            <a href="/" className="entry">
                                Вход
                            </a>
                            <a href="/" className="registration">
                                Регистрация
                            </a>
                            <span className="close">&times;</span>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export const HeaderClient = ({authCallback}) => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthentication = async () => {
            const auth = await checkAuth(); // Call your checkAuth function

            if (!auth.isAuthenticated) {
                navigate("/");
                return;
            }

            authCallback(auth);
        };

        checkAuthentication(); // Call the async function
    }, [authCallback, navigate]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <header className={styles.headerClient}>
            <div className={styles.container}>
                <a href="/">
                    <div className="logo h c">
                        <img className="crown" src={image1} alt="logo-crown"/>
                    </div>
                </a>
                <div className="right-bar h c">
                    <nav className={styles.nav}>
                        <a
                            href="/rules.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Правила
                        </a>
                        <HashLink to="/#questions">Вопросы</HashLink>
                        <Link to={`/winners`}>Победители</Link>
                        <HashLink to={`/#footer`}>Контакты</HashLink>
                    </nav>
                    <a href="tel:+375445572730" className={styles.tel}>
                    + 375 (44) 557 27 30
                    </a>
                    <button className={styles.user}>
                        <svg
                            className="_red-login"
                            width="18"
                            height="18"
                            viewBox="0 0 21 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.64844 21V19.75C1.64844 18.6009 1.87476 17.4631 2.31449 16.4015C2.75422 15.3399 3.39874 14.3753 4.21125 13.5628C5.02377 12.7503 5.98836 12.1058 7.04996 11.6661C8.11156 11.2263 9.24937 11 10.3984 11C11.5475 11 12.6853 11.2263 13.7469 11.6661C14.8085 12.1058 15.7731 12.7503 16.5856 13.5628C17.3981 14.3753 18.0427 15.3399 18.4824 16.4015C18.9221 17.4631 19.1484 18.6009 19.1484 19.75V21"
                                stroke="#363140"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                            <path
                                d="M10.3984 11C11.7245 11 12.9963 10.4732 13.934 9.53553C14.8717 8.59785 15.3984 7.32608 15.3984 6C15.3984 4.67392 14.8717 3.40215 13.934 2.46447C12.9963 1.52678 11.7245 1 10.3984 1C9.07236 1 7.80059 1.52678 6.8629 2.46447C5.92522 3.40215 5.39844 4.67392 5.39844 6C5.39844 7.32608 5.92522 8.59785 6.8629 9.53553C7.80059 10.4732 9.07236 11 10.3984 11V11Z"
                                stroke="#363140"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                        </svg>
                    </button>

                    <a href="/">
                        <img className="milko" src={image2} alt="logo-milka"/>
                    </a>

                    <input
                        className={styles.burgerToggle}
                        id="burger-toggle"
                        type="checkbox"
                        checked={isMenuOpen}
                        onChange={toggleMenu}
                    />
                    <label className={styles.burgerLabel} htmlFor="burger-toggle">
                        <span></span>
                    </label>
                    <ul className={styles.headerList} style={{backgroundImage: `url(${image21})`}}>
                        <li>
                            <a
                                href="/rules.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="top"
                                onClick={closeMenu}
                            >
                                Правила
                            </a>
                        </li>
                        <li>
                            <HashLink to="/#questions" className={styles.top}
                                      onClick={closeMenu}>Вопросы</HashLink>
                        </li>
                        <li>
                            <Link to={`/winners`} className={styles.top} onClick={closeMenu}>Победители</Link>
                        </li>
                        <li>
                            <HashLink to={`/#footer`} className={styles.top} onClick={closeMenu}>Контакты</HashLink>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
};