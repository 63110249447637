import React from 'react';
// import image3 from "../../img/image3.svg";
import image4 from "../../img/image4.png";
import image5 from "../../img/image5.png";
import styles from './css/Introduction.module.css';
import image10 from "../../img/desc.png";
import image11 from "../../img/mob.png";

export const Introduction = () => {
    return (
        <section
        className={styles.introduction}
        style={{
            backgroundImage: `url(${image10})`,
        }}
    >
        <style>
            {`
                @media (max-width: 759px) {
                    .${styles.introduction} {
                        background-image: url(${image11})!important;
                    }
                }
            `}
        </style>
            <div className={styles.container}>
                {/* <img className={styles.image} src={image3} alt="picnic"/> */}
                <h1 className={styles.title}>Волна нежности!</h1>
                <span
                    className={styles.introductionText}>20 ноября 2024 года — 14 января 2025 года</span>
                <h2>БОЛЬШЕ ПОКУПОК - БОЛЬШЕ КОДОВ</h2>
                <div className={styles.introductionBlock}>
                    <div className={styles.introductionCard}>
                        <img className={styles.prize} src={image4} alt="prize"/>
                        <h2>ГЛАВНЫЙ ПРИЗ</h2>
                        <p>автомобиль</p>
                        <h2>BELGEE X50</h2>
                    </div>
                    <div className={styles.introductionCard}>
                        <img className={styles.sertificate} src={image5} alt="prize"/>
                        <div className={styles.text}>
                            <h2>x160</h2>
                            <p>Подарочных сертификатов
                                торговой сети «Корона»</p>
                            <span>(каждую неделю розыгрыш 20 сертификатов)</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
        ;
};
