import React from 'react';
import styles from './css/Auth.module.css';

export const Auth = () => {
    return (
        <div className={styles.auth}>
            <h2>Авторизация</h2>
            <div className={styles.email}>
                <label htmlFor="email">Электронная почта</label>
                <input type="email" id="email" name="Введите почту"/>
            </div>
            <div className={styles.password}>
                <label htmlFor="password">Пароль</label>
                <input type="password" id="password" name="Введите пароль"/>
            </div>
            <div className={styles.option}>
                <label>
                    <input type="checkbox" id="remember-me" name="remember-me"/> Запомнить пароль
                </label>

                {/*TODO: Make component instead of link*/}
                {/*<a href="#" className="forgot-password">Забыли пароль?</a>*/}
            </div>
            <button className={styles.submit}>
                Вход
            </button>
            <button className={styles.submit}>
                Регистрация
            </button>
        </div>
    );
};