import React, {useState} from 'react';
import styles from './css/FAQ.module.css';
import minus from "../../img/minus.svg";
import plus from "../../img/Plus.svg";
import {Link} from "react-router-dom";

export const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const products = [
        {
            code: "7622210713315",
            name: "MILKA WAFFELINI CHOCOMAX (Милка Ваффелини Чокомакс). Вафля с начинкой с какао, покрытая молочным шоколадом Милка 31г"
        },
        {
            code: "57030692",
            name: "Вафли Milka Choco Wafer (Чоко Вафер) с начинкой с какао, покрытые молочным шоколадом 30г"
        },
        {code: "7622201410407", name: "Конфеты Милка из молочного шоколада с клубничной начинкой 110г"},
        {code: "7622201406813", name: "Конфеты Милка из молочного шоколада с молочной начинкой 110г"},
        {code: "7622201819590", name: "Конфеты Милка из молочного шоколада с начинкой с какао 110г"},
        {code: "7622201408145", name: "Конфеты Милка из молочного шоколада с ореховой начинкой 110г"},
        {code: "7622201459826", name: "Паста ореховая Milka с добавлением какао 350г"},
        {code: "7622210762542", name: "Печенье Милка, покрытое молочным шоколадом 200г"},
        {code: "7622210954404", name: "Печенье Milka Sensations с начинкой и кусочками молочного шоколада 156г"},
        {code: "7622202209994", name: "Печенье Милка с кусочками молочного шоколада 126г"},
        {code: "7622210762511", name: "Печенье Милка с кусочками молочного шоколада 168г"},
        {code: "7622201753207", name: "Печенье-палочки Milka Lila Sticks, покрытое молочным шоколадом 112г"},
        {
            code: "7622210762481",
            name: "Пирожное бисквитное Милка с шоколадной начинкой и кусочками молочного шоколада 175г"
        },
        {code: "7622201769291", name: "Шоколад молочный Милка 85г"},
        {
            code: "7622210679376",
            name: "Шоколад молочный MILKA MMMAX (МММАКС) Strawberry Cheesecake с начинкой со вкусом чизкейка, клубничной начинкой и печеньем 300г"
        },
        {
            code: "7622210694331",
            name: "Шоколад молочный MILKA MMMAX с карамельной начинкой с арахисом и с арахисовой начинкой с воздушным рисом и кусочками арахиса 276г"
        },
        {
            code: "7622210924568",
            name: "Шоколад молочный MILKA MMMAX с молочной и карамельной начинками и обжаренным цельным фундуком 300г"
        },
        {code: "7622210750495", name: "Шоколад молочный MILKA MMMAX с начинкой со вкусом ванили и печеньем Орео 300г"},
        {code: "7622202048326", name: "Шоколад молочный Милка 80г"},
        {
            code: "7622201771089",
            name: "Шоколад молочный Милка с добавлением орех. пасты из миндаля и дробленым карамелизованным солёным миндалем 85г"
        },
        {code: "7622210345806", name: "Шоколад молочный Милка с карамельной начинкой 90г"},
        {code: "7622210832498", name: "Шоколад молочный Милка с круглым печеньем Орео с начинкой со вкусом ванили 92г"},
        {code: "7622202048357", name: "Шоколад молочный Милка с фундуком и изюмом 80г"},
        {code: "7622201770990", name: "Шоколад молочный Милка с фундуком и изюмом 85г"},
        {code: "7622202048388", name: "Шоколад молочный Милка с цельным фундуком 80г"},
        {code: "7622201771140", name: "Шоколад молочный Милка с цельным фундуком 85г"},
        {code: "7622202048623", name: "Шоколад молочный пористый Milka Bubbles с бананово-йогуртовой начинкой 87г"},
        {code: "7622201769444", name: "Шоколад молочный пористый Milka Bubbles с бананово-йогуртовой начинкой 92г"},
        {code: "7622202048593", name: "Шоколад молочный пористый Milka Bubbles с кокосовой начинкой 87г"},
        {code: "7622201769352", name: "Шоколад молочный пористый Milka Bubbles с кокосовой начинкой 92г"},
        {code: "7622202048685", name: "Шоколад молочный пористый Milka Bubbles 72г"},
        {code: "7622201781880", name: "Шоколад молочный пористый Milka Bubbles 76г"},
    ];


    return (
        <section className={styles.faq} id="questions">
            <div className={styles.faqBlock}>
                <h2 className={styles.title}>Вопросы</h2>

                <div
                    className={`${styles.faqCard} ${openIndex === 0 ? styles.active : ''}`}
                    onClick={() => handleToggle(0)}
                >
                    <div className={styles.summary}>
                        <h2>Период, когда можно стать участником Рекламной игры?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 0 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 0 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 0 && (
                        <p>С 20 ноября 2024 года по 14 января 2025 года включительно.</p>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 1 ? styles.active : ''}`}
                    onClick={() => handleToggle(1)}
                >
                    <div className={styles.summary}>
                        <h2>Где проходит Рекламная игра?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 1 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 1 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 1 && (
                        <p>Рекламная игра проходит во всех магазинах «Корона», расположенных на территории Республики
                            Беларусь. Полный список адресов магазинов можно посмотреть <a href="/">здесь</a>.</p>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 2 ? styles.active : ''}`}
                    onClick={() => handleToggle(2)}
                >
                    <div className={styles.summary}>
                        <h2>Кто может принять участие в Рекламной игре?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 2 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 2 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 2 && (
                        <p>К участию в Рекламной игре приглашаются все граждане Республики Беларусь, постоянно
                            проживающие в Республике Беларусь, а также иностранные граждане и лица без гражданства,
                            имеющие вид на жительство на территории Республики Беларусь и постоянно проживающие в
                            Республике Беларусь, с учетом ограничений, изложенных в настоящих <a
                                href="/rules.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Правила
                            </a>.
                        </p>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 3 ? styles.active : ''}`}
                    onClick={() => handleToggle(3)}
                >
                    <div className={styles.summary}>
                        <h2>Как стать участником Рекламной игры?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 3 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 3 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 3 && (
                        <>
                            <p>1. Для участия в рекламной игре необходимо приобрести любой Игровой продукт из Таблицы №1
                                в период с 20 ноября 2024 года по 14 января 2025 года включительно в магазинах розничной
                                торговой сети «КОРОНА» ООО «ТАБАК-ИНВЕСТ», расположенных на территории Республики
                                Беларусь.</p>
                            <p>За каждые 2,00 (Два) белорусских рубля стоимости Игровых продуктов в одном Кассовом чеке
                                (с учетом всех скидок, действующих на момент расчёта) присваивается 1 (Один) Игровой
                                код.</p>
                            <p>Таблица №1. Игровые продукты:</p>
                            <div className={styles.tableContainer}>
                                <table className={styles.productTable}>
                                    <thead>
                                    <tr>
                                        <th>Код товара</th>
                                        <th>Наименование товара</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {products.map((product, index) => (
                                        <tr key={product.code}>
                                            <td>{product.code}</td>
                                            <td>{product.name}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <ol>
                                <li>Получить Кассовый чек, подтверждающий приобретение Игрового продукта и содержащий
                                    следующие сведения:
                                    <ul>
                                        <li>сведения о номере документа;</li>
                                        <li>наименование продавца (торгового объекта, где были приобретены Игровые
                                            продукты);
                                        </li>
                                        <li>учетный номер плательщика (УНП);</li>
                                        <li>дату и время продажи Игровых продуктов;</li>
                                        <li>наименование Игровых продуктов;</li>
                                        <li>количество и цену Игровых продуктов;</li>
                                        <li>итоговую сумму покупки.</li>
                                    </ul>
                                </li>
                                <li>Ознакомиться с правилами Рекламной игры, предоставить свое согласие на обработку
                                    персональных данных для участия в Рекламной игре посредством ознакомления с
                                    обязательной информацией об условиях обработки персональных данных и проставления
                                    отметки в соответствующем поле формы согласия на обработку персональных данных до
                                    регистрации на сайте <a
                                        href="https://korona-promo.by/">www.korona-promo.by</a>
                                </li>
                                <li>Зарегистрировать личный кабинет на Интернет-сайте <a
                                    href="https://www.korona-promo.by/">www.korona-promo.by</a>, заполнив
                                    регистрационную форму, достоверно указав следующую информацию:
                                    <ul>
                                        <li>фамилия (обязательно), имя (обязательно), отчество (при наличии);</li>
                                        <li>дата рождения (обязательно);
                                        </li>
                                        <li>абонентский номер мобильного телефона белорусского оператора сотовой связи
                                            Участника в международном формате (обязательно);
                                        </li>
                                        <li>адрес электронной почты (обязательно).</li>
                                    </ul>
                                </li>
                                <li>Загрузить на Интернет-сайт через личный кабинет фотографию регистрируемого Кассового
                                    чека. Изображение Кассового чека должно быть чётким и содержать:
                                </li>
                                <ul>
                                    <li>сведения о номере документа;</li>
                                    <li>наименование продавца (торгового объекта, где были приобретены Игровые
                                        продукты), учетный номер плательщика (УНП);
                                    </li>
                                    <li>абонентский номер мобильного телефона белорусского оператора сотовой связи
                                        Участника в международном формате (обязательно);
                                    </li>
                                    <li>дату и время продажи Игровых продуктов;</li>
                                    <li>наименование Игровых продуктов;</li>
                                    <li>количество и стоимость Игровых продуктов;</li>
                                    <li>итоговую сумму покупки.</li>
                                </ul>
                                <p>Кассовые чеки без наличия информации, указанной в настоящем пункте, либо без наличия
                                    Игровых продуктов, либо с наличием Игровых продуктов на сумму меньше 2,00 (Двух)
                                    белорусских рублей 00 копеек, не могут быть использованы для участия в Рекламной
                                    игре.</p>
                                <p>Данные из 1 (одного) Кассового чека, которые регистрирует Участник, принимаются к
                                    участию в Рекламной игре один раз.</p>
                                <li>Все Участники обязаны сохранить оригинал Кассового чека, подтверждающий приобретение
                                    Игровых продуктов до даты окончания Рекламной игры включительно.
                                </li>
                            </ol>
                        </>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 4 ? styles.active : ''}`}
                    onClick={() => handleToggle(4)}
                >
                    <div className={styles.summary}>
                        <h2>Есть ли ограничения по количеству чеков, которые можно зарегистрировать?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 4 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 4 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 4 && (
                        <p>Вы можете регистрировать неограниченное количество чеков: чем больше чеков вы регистрируете –
                            тем больше шансы на выигрыш Главного приза.</p>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 5 ? styles.active : ''}`}
                    onClick={() => handleToggle(5)}
                >
                    <div className={styles.summary}>
                        <h2>Сколько текущих призов может выиграть один участник Рекламной игры?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 5 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 5 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 5 && (
                        <>
                            <p>Каждый участник может выиграть не более 1 (одного) Приза №1 (Подарочный сертификат
                                «Корона»
                                номинальной стоимостью 100 (сто) белорусских рублей 00 копеек).</p>
                            <p>Все участники также участвуют в розыгрыше Главного приза.</p>
                        </>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 6 ? styles.active : ''}`}
                    onClick={() => handleToggle(6)}
                >
                    <div className={styles.summary}>
                        <h2>Какие товары необходимо купить для участия в рекламной игре?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 6 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 6 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 6 && (
                        <>
                            <p>Для участия в рекламной игре необходимо приобрести любой Игровой продукт. С полным
                                перечнем товаров, участвующих в рекламной игре можно ознакомиться <a
                                    href="/rules.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    здесь
                                </a>.</p>
                        </>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 7 ? styles.active : ''}`}
                    onClick={() => handleToggle(7)}
                >
                    <div className={styles.summary}>
                        <h2>Где можно получить информацию о правилах и условиях участия в Рекламной игре?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 7 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 7 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 7 && (
                        <p>
                            Информацию о правилах и условиях участия в Рекламной игре можно получить на Интернет-сайте
                            <a
                                href="https://www.korona-promo.by/">www.korona-promo.by</a>, а также по телефону горячей
                            линии: +375 44 557 27 30 с 10:00 до 17:00
                            без обеда в будние дни. В субботу с 10:00 до 13:00.
                        </p>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 8 ? styles.active : ''}`}
                    onClick={() => handleToggle(8)}
                >
                    <div className={styles.summary}>
                        <h2>Когда и где проходят розыгрыши?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 8 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 8 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 8 && (
                        <>
                            <p>Розыгрыши Призов проходят по адресу: г. Минск, ул. Интернациональная, 25, оф. 216. С
                                графиком проведения розыгрышей можно ознакомиться <a
                                    href="/rules.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    здесь
                                </a>.</p>
                        </>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 9 ? styles.active : ''}`}
                    onClick={() => handleToggle(9)}
                >
                    <div className={styles.summary}>
                        <h2>Как узнать о результатах розыгрыша?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 9 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 9 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 9 && (
                        <p>Победители Рекламной игры извещаются о выигрыше звонком на номер телефона, указанный при
                            регистрации на Интернет-сайте, в течение 4 (четырёх) дней с даты розыгрыша.
                            Организатор также оставляет за собой право уведомлять Победителей любым удобным ему
                            способом, помимо уже указанных: SMS сообщением на номер телефона и/или почтовым уведомлением
                            на электронный адрес Участника, указанный в личном кабинете при регистрации.
                            Также список победителей можно посмотреть в разделе – <Link to={`/winners`}
                                                                                        className="link">
                                Победители
                            </Link>.</p>
                    )}
                </div>

                <div
                    className={`${styles.faqCard} ${openIndex === 10 ? styles.active : ''}`}
                    onClick={() => handleToggle(10)}
                >
                    <div className={styles.summary}>
                        <h2>Где и когда можно получить приз?</h2>
                        <img
                            src={plus}
                            alt="plus"
                            className={styles.iconPlus}
                            style={{display: openIndex === 10 ? 'none' : 'block'}}
                        />
                        <img
                            src={minus}
                            alt="minus"
                            className={styles.iconMinus}
                            style={{display: openIndex === 10 ? 'block' : 'none'}}
                        />
                    </div>
                    {openIndex === 10 && (
                        <>
                            <p>Для получения Приза №1 (Подарочный сертификат «Корона» номинальной стоимостью 100 (сто)
                                белорусских рублей 00 копеек) Победителю нужно в срок не позднее, чем за 7 (семь) дней
                                до окончания периода выдачи приза предоставить Организатору по электронной почте
                                game@fresh-ideas.by данные, необходимые для отправки Приза по почте, а именно: фамилию,
                                имя, отчество (при наличии) Победителя, контактный номер телефона, адрес
                                пребывания/проживания в Республике Беларусь: индекс, область, район, населенный пункт,
                                улица (при наличии), дом (при наличии), корпус (при наличии), номер квартиры (при
                                наличии).</p>
                            <p>Призы будут отправлены посредством заказного почтового отправления с уведомлением о
                                доставке в течение 7 (семи) дней со дня получения данных от Победителя на электронную
                                почту Организатора (game@fresh-ideas.by), необходимых для отправления Приза почтой.
                                Организатор считается выполнившим свои обязательства по выдаче приза в день
                                соответствующего отправления. Денежная компенсация стоимости призов не
                                производится.</p>
                            <p>Главный приз (легковой автомобиль BELGEE X50) можно получить в течение месяца со дня
                                признания Участника рекламной игры Победителем по адресу: Республика Беларусь, 220030,
                                г. Минск, ул. Интернациональная, д.25а, оф. 216, в рабочие дни с 10:00 до 17:00, а также
                                в субботу с 10:00 до 13:00. Дата признания Участника рекламной игры победителем является
                                дата розыгрыша, указанная в Таблице №4 п.12.1 настоящих <a
                                    href="/rules.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Правил
                                </a>.</p>
                            <p>Дату и время прибытия необходимо предварительно согласовать с Организатором по телефону
                                +375 (44) 557-27-30 (по будним дням с 10:00 до 17:00, а также в субботу с 10:00 до
                                13:00). Звонок платный, в соответствии с тарифами операторов, услугами которых
                                пользуется Участник. Для получения Главного приза Победитель должен предоставить
                                Организатору свои паспортные данные (фамилия, имя, отчество (при наличии), серия и номер
                                паспорта/вида на жительство/ид.карты, дата выдачи и кем выдан документ, удостоверяющий
                                личность, адрес регистрации) лично или по электронной почте game@fresh-ideas.by.</p>
                            <p>Главный приз передаётся Победителю в день явки Победителя за получением Приза в срок,
                                указанный в Таблице №4 п.12.1 настоящих <a
                                    href="/rules.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Правил
                                </a>.</p>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};