import React from 'react';
import styles from './css/Privacy.module.css';
import {FooterPublic} from "../shared/Footer";
import {Introduction} from "./Introduction";

export const Privacy = () => {
    return (<div className={styles.public}>
        <Introduction/>
        <div className={styles.container1}>
            <div className={styles.text}>
                <p className={styles.subtitle2}>ПОЛИТИКА <br/> <br/> обработки персональных данных ЧРУП «Промофреш»
                </p>
                <h1 className={styles.title}>1. ОБЩИЕ ПОЛОЖЕНИЯ</h1>
                <div className={styles.textBlock}>
                    <p>1.1. Настоящая Политика обработки персональных данных ЧРУП «Промофреш» (далее – Политика)
                        разработана во исполнение требований Закона Республики Беларусь от 07 мая 2021 г. № 99-З «О
                        защите персональных данных» (далее – Закон) в целях защиты персональных данных, прав и
                        свобод физических лиц при обработке их персональных данных и направлена на обеспечение
                        прозрачного характера обработки персональных данных. </p>
                    <p>1.2. Политика применяется к отношениям, связанным с обработкой ЧРУП «Промофреш» персональных
                        данных клиентов, кандидатов на трудоустройство, лиц, с которыми ЧРУП «Промофреш» заключаются
                        договоры гражданско-правового характера, контрагентов ЧРУП «Промофреш», аффилированных лиц
                        ЧРУП «Промофреш».</p>
                    <p>1.3. Политика разъясняет субъектам персональных данных их права в отношении обработки их
                        персональных данных, а также для каких целей и каким образом их персональные данные
                        собираются, используются или иным образом обрабатываются, а также определяет имеющиеся, в
                        связи с этим у субъектов персональных данных права и механизм их реализации.</p>
                    <p>1.4. Контакты ЧРУП «Промофреш»: УНП 691757897; юридический адрес: 220125 РБ, г. Минск, ул.
                        Городецкая, д.44, кв.155А; почтовый адрес: 220030, РБ, г. Минск, ул. Интернациональная, 25А,
                        офис 216; электронная почта: n.jadzevic@fresh-ideas.by.</p>
                    <p>1.5. Обработка персональных данных осуществляется следующими способами: <br/>
                        – с использованием средств автоматизации с передачей полученной информации по
                        информационно-коммуникационным сетям и без такой передачи; <br/>
                        – без использования средств автоматизации; <br/>
                        – смешанным способом обработки персональных данных.</p>
                    <p>1.6. Настоящая Политика размещается в свободном доступе в объектах ЧРУП «Промофреш».</p>
                    <p>1.7. Настоящая Политика действует с момента ее утверждения. </p>

                    <h2 className={styles.title}>2. ТЕРМИНЫ И ИХ ОПРЕДЕЛЕНИЯ</h2>
                    <p><span className={styles.boldText}>Персональные данные</span> – любая информация, относящаяся
                        к идентифицированному физическому лицу
                        или физическому лицу, которое может быть идентифицировано.</p>
                    <p><span className={styles.boldText}>Оператор</span> – государственный орган, юридическое лицо
                        Республики Беларусь, иная организация, физическое лицо, в том числе индивидуальный
                        предприниматель (далее, если не определено иное, – физическое лицо), самостоятельно или
                        совместно с иными указанными лицами организующие и (или) осуществляющие обработку
                        персональных данных. </p>
                    <p><span className={styles.boldText}>Уполномоченное лицо</span> – государственный орган,
                        юридическое лицо Республики Беларусь, иная организация, физическое лицо, которые в
                        соответствии с актом законодательства, решением государственного органа, являющегося
                        оператором, либо на основании договора с оператором осуществляют обработку персональных
                        данных от имени оператора или в его интересах.</p>
                    <p><span className={styles.boldText}>Субъект персональных данных</span> – физическое лицо, в
                        отношении которого осуществляется обработка персональных данных. </p>
                    <p><span className={styles.boldText}>Обработка персональных данных</span> – любое действие или
                        совокупность действий, совершаемые с персональными данными, включая: сбор, систематизацию,
                        хранение, изменение, использование, обезличивание, блокирование, распространение,
                        предоставление, удаление персональных данных.</p>
                    <p><span className={styles.boldText}>Блокирование персональных данных</span> – прекращение
                        доступа к персональным данным без их удаления.</p>
                    <p><span className={styles.boldText}>Обезличивание персональных данных</span> – действия, в
                        результате которых становится невозможным без использования дополнительной информации
                        определить принадлежность персональных данных конкретному субъекту персональных данных.</p>
                    <p><span className={styles.boldText}>Предоставление персональных данных</span> – действия,
                        направленные на ознакомление с персональными данными определенных лица или круга лиц.</p>
                    <p><span className={styles.boldText}>Распространение персональных данных</span> – действия,
                        направленные на ознакомление с персональными данными неопределенного круга лиц.</p>
                    <p><span className={styles.boldText}>Удаление персональных данных</span> – действия, в
                        результате которых становится невозможным восстановить персональные данные в информационных
                        ресурсах (системах), содержащих персональные данные, и (или) в результате которых
                        уничтожаются материальные носители персональных данных.</p>
                    <p><span className={styles.boldText}>Трансграничная передача персональных данных</span> –
                        передача персональных данных на территорию иностранного государства.</p>
                    <p><span className={styles.boldText}>Файлы cookie</span> – небольшие файлы с текстовой и (или)
                        цифровой информацией, которые при посещении Сайта передаются в браузер устройства
                        пользователя и при последующей попытке пользователя открыть страницу Сайта, браузер
                        пересылает серверу соответствующие файлы с целью аутентификации пользователя, хранения его
                        персональных предпочтений и настроек, отслеживания состояния сессии доступа пользователя и
                        т.д.</p>
                    <p>Иные используемые в тексте Политики термины употребляются в значениях, принятых в действующем
                        законодательстве Республики Беларусь.</p>

                    <h2 className={styles.title}>3. ОСНОВНЫЕ ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ,
                        МЕХАНИЗМ ИХ РЕАЛИЗАЦИИ</h2>
                    <p>3.1. Субъект персональных данных имеет право:</p>
                    <p>- на отзыв своего согласия, если для обработки персональных данных ЧРУП «Промофреш»
                        обращалась к субъекту персональных данных за получением согласия. В этой связи право на
                        отзыв согласия не может быть реализовано в случае, когда обработка осуществляется на
                        основании договора (например, при реализации образовательных программ) либо в соответствии с
                        требованиями законодательства (например, при проведении контроля либо рассмотрении
                        поступившего обращения);</p>
                    <p>- на получение информации, касающейся обработки своих персональных данных, содержащей:</p>

                    <ul>
                        <li>место нахождения ЧРУП «Промофреш»;</li>
                        <li>подтверждение факта обработки персональных данных обратившегося лица ЧРУП «Промофреш»;
                        </li>
                        <li>его персональные данные и источник их получения;</li>
                        <li>правовые основания и цели обработки персональных данных;</li>
                        <li>срок, на который дано его согласие (если обработка персональных данных осуществляется на
                            основании согласия);
                        </li>
                        <li>наименование и место нахождения уполномоченного лица (уполномоченных лиц);</li>
                        <li>иную информацию, предусмотренную законодательством;</li>
                    </ul>

                    <p>- требовать от ЧРУП «Промофреш» внесения изменений в свои персональные данные в случае, если
                        персональные данные являются неполными, устаревшими или неточными. В этих целях субъект
                        персональных данных предоставляет соответствующие документы и (или) их заверенные в
                        установленном порядке копии, подтверждающие необходимость внесения изменений в персональные
                        данные;</p>
                    <p>- получать от ЧРУП «Промофреш» информацию о предоставлении своих персональных данных,
                        обрабатываемых ЧРУП «Промофреш», третьим лицам. Такое право может быть реализовано один раз
                        в календарный год, а предоставление соответствующей информации осуществляется бесплатно;</p>
                    <p>- требовать от ЧРУП «Промофреш» бесплатного прекращения обработки своих персональных данных,
                        включая их удаление, при отсутствии оснований для обработки персональных данных,
                        предусмотренных Законом и иными законодательными актами;</p>
                    <p>- обжаловать действия (бездействие) и решения ЧРУП «Промофреш», нарушающие его права при
                        обработке персональных данных, в уполномоченный орган по защите прав субъектов персональных
                        данных в порядке, установленном законодательством Республики Беларусь об обращениях граждан
                        и юридических лиц.</p>
                    <p>3.2. Для реализации своих прав, связанных с обработкой персональных данных ЧРУП «Промофреш»,
                        субъект персональных данных подает ЧРУП «Промофреш» заявление в письменной форме или в виде
                        электронного документа (а в случае реализации права на отзыв согласия – также в форме, в
                        которой такое согласие было получено) соответственно по почтовому адресу или адресу
                        электронной почты лица ответственного за осуществление внутреннего контроля за обработкой
                        персональных данных в ЧРУП «Промофреш», указанным в настоящей Политики. </p>
                    <p>Такое заявление должно содержать:</p>
                    <p>- фамилию, собственное имя, отчество (если таковое имеется) субъекта персональных данных,
                        адрес его места жительства (места пребывания);</p>
                    <p>- дату рождения субъекта персональных данных;</p>
                    <p>- изложение сути требований субъекта персональных данных;</p>
                    <p>- идентификационный номер субъекта персональных данных, при отсутствии такого номера – номер
                        документа, удостоверяющего личность субъекта персональных данных, в случаях, если эта
                        информация указывалась субъектом персональных данных при даче своего согласия или обработка
                        персональных данных осуществляется без согласия субъекта персональных данных;</p>
                    <p>- личную подпись (для заявления в письменной форме) либо электронную цифровую подпись (для
                        заявления в виде электронного документа) субъекта персональных данных.</p>

                    <h2>Организация не рассматривает заявления субъектов персональных данных, направленные иными
                        способами (e-mail, телефон, факс и т.п).</h2>


                    <p>3.3. Право на отзыв согласия не может быть реализовано в случае, когда обработка персональных
                        данных осуществляется на основании договора либо иных правовых основаниях, предусмотренных
                        Законом.</p>
                    <p>3.4. Электронное сообщение о реализации права на отзыв согласия, которое было предоставлено
                        субъектом персональных данных в иной электронной форме, должно содержать перечень
                        персональных данных, на обработку которых субъект персональных данных предоставлял свое
                        согласие в иной электронной форме и изложение сути требований.</p>
                    <p>3.5. В ЧРУП «Промофреш» ответственным за осуществление внутреннего контроля за обработкой
                        персональных данных – директор Чепиков Е.И. Субъект персональных данных вправе обратиться за
                        содействием в реализации своих прав к директору Чепикову Е.И., направив электронное
                        сообщение на адрес электронной почты n.jadzevic@fresh-ideas.by.</p>

                    <h2 className={styles.title}>4. ОСНОВНЫЕ ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                    <p>4.1. Обработка персональных данных осуществляется с учетом необходимости обеспечения защиты
                        прав и свобод субъектов персональных данных, в том числе защиты права на неприкосновенность
                        частной жизни, личную и семейную тайну, на основе следующих принципов:</p>
                    <p>– обработка персональных данных осуществляется на законной и справедливой основе;</p>
                    <p>– обработка персональных данных осуществляется соразмерно заявленным целям их обработки и
                        обеспечивает на всех этапах такой обработки справедливое соотношение интересов всех
                        заинтересованных лиц;</p>
                    <p>– обработка персональных данных осуществляется с согласия субъекта персональных данных, за
                        исключением случаев, предусмотренных законодательными актами;</p>
                    <p>– обработка персональных данных ограничивается достижением конкретных, заранее заявленных
                        законных целей. Не допускается обработка персональных данных, несовместимая с первоначально
                        заявленными целями их обработки;</p>
                    <p>– содержание и объем обрабатываемых персональных данных соответствуют заявленным целям их
                        обработки. Обрабатываемые персональные данные не являются избыточными по отношению к
                        заявленным целям их обработки;</p>
                    <p>– обработка персональных данных носит прозрачный характер. Субъекту персональных данных может
                        предоставляться соответствующая информация, касающаяся обработки его персональных
                        данных;</p>
                    <p>– оператор (уполномоченное лицо) принимает меры по обеспечению достоверности обрабатываемых
                        им персональных данных, при необходимости обновляет их;</p>
                    <p>– хранение персональных данных осуществляется в форме, позволяющей идентифицировать субъекта
                        персональных данных, не дольше, чем этого требуют заявленные цели обработки персональных
                        данных.</p>

                    <h2 className={styles.title}>5. ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ, ЦЕЛИ
                        ОБРАБОТКИ</h2>
                    <p>5.1. Обработка персональных данных ЧРУП «Промофреш» как Оператором осуществляется в
                        соответствии с требованиями законодательства Республики Беларусь.</p>
                    <p>5.2. Обработка персональных данных осуществляется с согласия субъектов персональных данных, а
                        также на иных правовых основаниях, предусмотренных законодательством Республики
                        Беларусь.</p>
                    <p>5.3. ЧРУП «Промофреш» как Оператор устанавливает цели обработки персональных данных,
                        категории субъектов персональных данных, чьи персональные данные подвергаются обработке,
                        перечень обрабатываемых персональных данных, правовые основания обработки персональных
                        данных и срок их хранения согласно Приложению, являющемуся неотъемлемой частью настоящей
                        Политики.</p>
                    <p>5.4. Предоставляя ЧРУП «Промофреш» как Оператору свое согласие на обработку персональных
                        данных, субъекты персональных данных соглашаются на совершение ЧРУП «Промофреш» как
                        Оператором следующих действий с персональными данными как с использованием средств
                        автоматизации, так и без их использования: сбор, систематизацию, хранение, изменение,
                        использование, обезличивание, блокирование, предоставление, удаление. В случаях, если
                        обработка персональных данных предполагает их распространение, то ЧРУП «Промофреш» как
                        Оператор указывает конкретные условия, при которых возможно такое действие.</p>
                    <p>5.5. ЧРУП «Промофреш» как Оператор ограничивает обработку персональных данных достижением
                        конкретных, заранее заявленных целей обработки, а хранение персональных данных
                        осуществляется в форме, позволяющей идентифицировать субъекта персональных данных не дольше,
                        чем этого требуют заявленные цели обработки.</p>
                    <p>5.6. ЧРУП «Промофреш» как Оператор осуществляет обработку только тех персональных данных,
                        которые необходимы для выполнения заявленных целей и не допускает их избыточной
                        обработки. </p>
                    <p>5.7. ЧРУП «Промофреш» как Оператор осуществляет обработку персональных данных с привлечением
                        уполномоченных лиц, в целях, перечисленных в Приложении к настоящей Политике, в том числе
                        для осуществления информационно-технологической поддержки информационных ресурсов (систем)
                        ЧРУП «Промофреш», рассылки информационных, рекламных и иных сообщений от имени ЧРУП
                        «Промофреш».</p>
                    <p>Уполномоченные лица, осуществляющие обработку персональных данных на основании заключаемого с
                        ЧРУП «Промофреш» соглашения об обработке персональных данных (поручения), согласно которому
                        обязуются соблюдать принципы, правила обработки и защиты персональных данных,
                        предусмотренные законодательством.</p>
                    <p>Для каждого уполномоченного лица в соглашении об обработке персональных данных определяются
                        перечень действий с персональными данными, которые будут совершаться таким лицом,
                        осуществляющим обработку персональных данных, цели обработки, устанавливается обязанность
                        такого лица соблюдать конфиденциальность и обеспечивать безопасность персональных данных при
                        их обработке, указываются необходимые меры и требования к защите обрабатываемых персональных
                        данных в соответствии с законодательством Республики Беларусь.</p>
                    <p>Если для обработки персональных данных по поручению ЧРУП «Промофреш» как Оператора необходимо
                        получение согласия субъекта персональных данных, такое согласие получает ЧРУП «Промофреш»
                        как Оператор. Ответственность перед субъектом персональных данных за действия
                        уполномоченного лица, которому Оператор поручил обработку персональных данных, несет ЧРУП
                        «Промофреш» как Оператор.</p>
                    <p>5.8. В целях обеспечения корректной работы и улучшения функционирования Сайта, удобства его
                        использования, создания персонализированной рекламы и сбора аналитической информации в
                        обобщенном виде ЧРУП «Промофреш» использует файлы cookie.</p>

                    <h2 className={styles.title}>6. СПОСОБЫ ПОЛУЧЕНИЯ СОГЛАСИЯ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ НА
                        ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                    <p>6.1. Согласие субъекта персональных данных представляет собой свободное, однозначное,
                        информированное выражение его воли, посредством которого он разрешает обработку своих
                        персональных данных. </p>
                    <p>6.2. Согласие субъекта персональных данных может быть получено ЧРУП «Промофреш» как
                        Оператором в письменной форме, в виде электронного документа или в иной электронной форме. В
                        иной электронной форме ЧРУП «Промофреш» как Оператор получает согласие субъекта персональных
                        данных посредством проставления субъектом персональных данных соответствующей отметки на
                        Сайте. </p>

                    <h2 className={styles.title}>7. ОСОБЕННОСТИ ОБРАБОТКИ ФАЙЛОВ COOKIE</h2>
                    <p>7.1. Настоящий раздел Политики разъясняет пользователям какие типы файлов cookie используются
                        на Сайте, для каких целей и каким образом ЧРУП «Промофреш» обрабатывает файлы cookie.</p>
                    <p>7.2. Файлы сookie — это файлы с данными, которые размещаются (хранятся) на устройстве
                        пользователя (компьютере, смартфоне, планшете, смарт-часах и др.), когда он посещает
                        Сайт.</p>
                    <p>7.3. Полученная при использовании файлов сookie информация может включать сведения о браузере
                        и устройстве пользователя, данные, собранные в процессе автоматического электронного
                        взаимодействия устройства пользователя и Сайта, в том числе географические и геолокационные,
                        а также статистическую и маркетинговую информацию.</p>
                    <p>7.4. ЧРУП «Промофреш» обрабатывает файлы cookie в целях: </p>
                    <p>- корректного функционирования Сайта и повышение удобства его использования.</p>
                    <p>7.5. ЧРУП «Промофреш» не использует файлы cookie для идентификации конкретного пользователя
                        Сайта.</p>
                    <p>7.6. Обеспечение неограниченного доступа к Политике реализуется путем ее публикации в
                        открытом доступе на Сайте.</p>
                    <p>7.7. Сайт использует следующие типы файлов cookie:</p>
                    <p>7.7.1. Технические/функциональные файлы cookie: используются для осуществления базовых
                        функций и корректного отображения содержимого Сайта в браузере пользователя, например,
                        сохранение настроек пользователя, поддержка аутентификации, обеспечение безопасности и
                        конфиденциальности данных, управление сессией пользователя и предоставление технической
                        поддержки. Данный тип файлов является обязательным и не подлежит отключению.</p>
                    <p>7.8. ЧРУП «Промофреш» обеспечивает полноценное и корректное функционирование Сайта, в том
                        числе безопасность его использования, только с использованием технических/функциональных
                        файлов cookie, которые являются обязательным и не подлежат отключению.</p>
                    <p>7.9. ЧРУП «Промофреш» использует следующие типы файлов cookie:</p>
                    <p>- технические (функциональные) файлы cookie, являющиеся обязательными. Данные файлы cookie
                        необходимы для обеспечения корректной реализации функциональных возможностей Сайта, в том
                        числе использования предлагаемых на нем сервисов. Указанный тип файлов cookie является
                        неотключаемым;</p>
                    <p>В зависимости от сроков хранения в браузере устройства Пользователя файлы cookie,
                        используемые ЧРУП «Промофреш», классифицируются как:</p>
                    <p>- сеансовые файлы cookie, которые являются временными и хранятся до момента закрытия браузера
                        Пользователем или окончания сеанса использования Сайта;</p>
                    <p>- постоянные файлы cookie, которые подлежат хранению в памяти устройства Пользователя в
                        течение времени, указанного в параметрах файлов cookie, или до момента их непосредственного
                        удаления самим Пользователем.</p>
                    <p>7.11. ЧРУП «Промофреш», участвуя в трансграничной передаче данных, гарантирует, что такая
                        передача полностью соответствует требованиям Закона. Так, доступ к персональным данным
                        ограничивается путем предоставления полномочий только тем работникам ЧРУП «Промофреш»,
                        которым это необходимо для выполнения своих должностных обязанностей. ЧРУП «Промофреш»
                        применяет методы минимизации данных для ограничения объема персональных данных, которые
                        могут быть переданы в сторонние юрисдикции.</p>
                    <p>7.12. ЧРУП «Промофреш», используя сторонние файлы cookie, может осуществлять трансграничную
                        передачу агрегированных (объединенных в общий массив сведений о Пользователях по их
                        поведенческим особенностям) данных, не связанных с идентификацией конкретного Пользователя,
                        так как это необходимо для достижения целей обработки файлов cookie и непосредственно
                        связано особенностями их функционирования.</p>
                    <p>Передача сведений из файлов cookie осуществляется в государства, на территории которых
                        обеспечивается надлежащий уровень защиты прав субъектов персональных данных, а именно: в
                        государства, являющиеся сторонами Конвенции Совета Европы о защите физических лиц при
                        автоматизированной обработке персональных данных, принятой в г. Страсбурге 28 января 1981
                        года (далее — Конвенция).</p>
                    <p>В рамках функционирования сервиса веб-аналитики Google Analytics и сервиса показа контекстной
                        рекламы Google AdSense ЧРУП «Промофреш» также может осуществлять трансграничную передачу
                        агрегированных (объединенных в общий массив сведений о Пользователях по их поведенческим
                        особенностям) данных, не связанных с идентификацией конкретного Пользователя, на серверы
                        Google, Inc., находящиеся в Соединенных Штатах Америки (далее — США). США не относится к
                        числу государств, на территории которых, согласно Конвенции, обеспечивается надлежащий
                        уровень защиты прав субъектов персональных данных. Таким образом, передача персональных
                        данных в указанное государство может сопровождаться следующими рисками:</p>
                    <p>- возможность сбора данных правительством США согласно Указу Президента США 12333,
                        подписанному 4 декабря 1981 г. (далее — Указ 12333) и параграфу 702 Закона о наблюдении за
                        иностранными разведками, принятого в 1978 году (далее — §702 Закона о наблюдении).</p>
                    <p>ЧРУП «Промофреш» считает необходимым отметить, что риски, связанные с требованиями
                        вышеуказанных нормативных актов, непосредственно не относятся к обработке персональных
                        данных ЧРУП «Промофреш». В частности, ЧРУП «Промофреш» является организацией, созданной в
                        соответствии с требованиями законодательства Республики Беларусь, а, следовательно, не может
                        быть субъектом, на которого распространяются приказы о первичном или массовом наблюдении
                        согласно §702 Закона о наблюдении. Кроме того, ЧРУП «Промофреш» не помогает и не может быть
                        обязана помогать властям США в сборе информации на основании Указа 12333. ЧРУП «Промофреш»
                        не оказывает и не будет оказывать никакой помощи американским властям, осуществляющим
                        наблюдение в соответствии с Указом 12333. Также ЧРУП «Промофреш» не предпринимает и не может
                        быть обязана предпринимать какие-либо действия для облегчения массового наблюдения согласно
                        Указу 12333.</p>
                    <p><span className={styles.boldText}>Принимая во внимание вышеприведенный анализ, ЧРУП «Промофреш» считает, что риск
                            причинения вреда субъекту персональных данных, связанный с особенностями правового режима в США, является минимальным.</span>
                    </p>
                    <p>7.13. При первом посещении пользователя на Сайт его посетителю предлагается дать согласие на
                        использование файлов cookie либо отказаться от их использования посредством нажатия кнопок
                        «принять» или «отклонить» на всплывающем cookie-баннере.</p>
                    <p>При нажатии кнопки «принять», пользователь дает согласие на использование всех типов файлов
                        cookie, указанных в настоящей Политике. При нажатии кнопки «отклонить» пользователь
                        отказывается от использования аналитических и рекламных файлов cookie.</p>
                    <p>На всплывающем cookie баннере пользователь может настроить использование конкретного типа
                        файлов cookie, за исключением типа «технические/функциональные (обязательные) файлы cookie»,
                        через форму «персональные настройки cookie», которая находится на самом баннере или в
                        подвале (нижняя часть страницы) Сайта.</p>
                    <p>Сайт сохраняет выбор пользователя о настройках файлов cookie в течение 1 (одного) года. По
                        окончании этого периода Сайт вновь запросит согласие пользователя посредством повторно
                        всплывающего cookie-баннера.</p>
                    <p>Пользователь Сайта может изменить свой выбор, а также отказаться от использования файлов
                        cookie до истечения срока, указанного в пункте 3.2 настоящей Политики, через форму
                        «Персональные настройки cookie» в подвале (нижняя часть страницы) Сайта.</p>
                    <p>Пользователь может произвести ручную очистку устройства и (или) отказаться от использования
                        файлов cookie, обратившись к настройкам конфигурации браузера при этом некоторые браузеры
                        позволяют посещать сайты в режиме «инкогнито» для автоматического удаления сессионных файлов
                        cookie. Подробнее о параметрах управления файлами cookie можно ознакомиться, перейдя по
                        внешним ссылкам, ведущим на сайты основных браузеров:</p>
                    <p>- Microsoft Internet Explorer -
                        <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11">https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11;</a>
                    </p>
                    <p>- Microsoft Edge -
                        <a href="https://support.microsoft.com/en-us/microsoft-edge/view-cookies-in-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879">https://support.microsoft.com/en-us/microsoft-edge/view-cookies-in-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879;</a>
                    </p>
                    <p>- Google Chrome - <a
                        href="https://support.google.com/chrome/answer/95647?hl=en&hlrm=en">https://support.google.com/chrome/<br/>answer/95647?hl=en&hlrm=en;</a>
                    </p>
                    <p>- Safari - <a
                        href="https://support.apple.com/ru-ru/guide/safari/sfri11471/mac/">https://support.apple.com/ru-ru/guide/safari/sfri11471/mac/;</a>
                    </p>
                    <p>- Firefox -
                        <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US;</a>
                    </p>
                    <p>- Android Browser -
                        <a href="https://support.google.com/nexus/answer/54068?visit_id=1-636585517588941681-639659396&hl=en&rd=1">https://support.google.com/nexus/<br/>answer/54068?visit_id=1-636585517588941681-639659396&hl=en&rd=1;</a>
                    </p>
                    <p>- Opera - <a href="https://www.opera.com/ru/help/">https://www.opera.com/ru/help/;</a>
                    </p>

                    <p><span
                        className={styles.boldText}>Список указанных выше браузеров не является исчерпывающим.</span>
                    </p>
                    <h2 className={styles.title}>8. ОСОБЕННОСТИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПРИ ОСУЩЕСТВЛЕНИИ
                        АДМИНИСТРАТИВНЫХ ПРОЦЕДУР</h2>

                    <div className={styles.tableContainer}>
                        <table className={styles.privacyTable}>
                            <thead>
                            <tr>
                                <th>Предварительная запись на личный прием/личный прием</th>
                                <th>Ответственное структурное подразделение</th>
                                <th>Лица, обращающиеся на личный прием/посетители</th>
                                <th>Фамилия, собственное имя, отчество (при его наличии), контактный телефон, суть
                                    обращения, иные данные, имеющие значение для рассмотрения обращения по существу
                                </th>
                                <th>Обработка персональных данных является необходимой для выполнения обязанностей
                                    (полномочий), предусмотренных законодательными актами (абзац 20 статьи 6 Закона
                                    Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных», пункт 7
                                    статьи 6 Закона Республики Беларусь от 18.07.2011 № 300-З «Об обращениях граждан и
                                    юридических лиц»).
                                </th>
                                <th>Не передаются</th>
                                <th>1 год с даты последнего обращения (п. 92 Перечня)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Работа с аффилированными лицами</td>
                                <td>Ответственное структурное подразделение</td>
                                <td>Лица, являющиеся аффилированными согласно ст. 56 Закона Республики Беларусь «О
                                    хозяйственных обществах»
                                </td>
                                <td>Фамилия, им, отчество (при наличии), адрес регистрации, номер телефона</td>
                                <td>Республики Беларусь «О хозяйственных обществах»</td>
                                <td>аффилированным лицам по запросу в целях исполнения требований Закона Республики
                                    Беларусь «О хозяйственных обществах»
                                </td>
                                <td>5 рабочих дней с момента получения уведомления о том, что лицо утратило статус
                                    аффилированного
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <h2 className={styles.title}>9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ </h2>
                    <p>9.1. ЧРУП «Промофреш» имеет право по своему усмотрению в одностороннем порядке изменить и
                        (или) дополнить условия настоящей Политики без предварительного уведомления субъектов
                        персональных данных посредством размещения на Сайте новой редакции Политики. </p>
                    <p>9.2. Вопросы, касающиеся обработки персональных данных, не затронутые в настоящей Политике,
                        регулируются законодательством Республики Беларусь. </p>

                    <div className={styles.head}>
                        <div className={styles.headLeft}>
                            <p>Директор ЧРУП «Промофреш»</p>
                        </div>
                        <div className={styles.headRight}>
                            <p>Чепиков Е.И.</p>
                        </div>
                    </div>

                    <h2>Приложение к Политике обработки персональных данных ЧРУП «Промофреш»</h2>
                    <p><span className={styles.boldText}>Перечень обрабатываемых ЧРУП «Промофреш» персональных данных, цели их обработки,</span>
                    </p>
                    <p><span className={styles.boldText}>категории субъектов персональных данных, чьи данные подвергаются обработке,</span>
                    </p>
                    <p><span className={styles.boldText}>правовые основания обработки, сроки хранения персональных данных</span>
                    </p>

                    <div className={styles.tableContainer}>
                        <table className={styles.privacyTable}>
                            <thead>
                            <tr>
                                <th>Цель обработки персональных данных</th>
                                <th>Подразделение (лицо), ответственное за обработку</th>
                                <th>Категории лиц, чьи персональные данные обрабатываются</th>
                                <th>Категории персональных данных, которые обрабатываются</th>
                                <th>Правовая основа обработки персональных данных</th>
                                <th>Категории получателей персональных данных</th>
                                <th>Срок хранения персональных данных</th>
                            </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <td>Рассмотрение резюме (анкет) соискателей на вакантные должности в целях заключения
                                    трудового договора
                                </td>
                                <td>Отдел кадров</td>
                                <td>Физические лица, которые направили резюме</td>
                                <td>Фамилия, собственное имя, отчество, год рождения, сведения об образовании и опыте
                                    работы, контактный номер телефона, адрес электронной почты
                                </td>
                                <td>Согласие – ст. 5 Закона Республики Беларусь от 7 мая 2021 г. №99-З «О защите
                                    персональных данных» (далее - Закон) при направлении резюме (анкеты) в электронном
                                    виде;
                                </td>
                                <td>Не передаются</td>
                                <td>В случае непринятия на работу – 1 год <br/><br/> В случае принятия на работу – 1
                                    месяц
                                </td>
                            </tr>

                            <tr>
                                <td>Оформление (прием) на работу</td>
                                <td>Отдел кадров</td>
                                <td>Соискатели работы, члены их семей</td>
                                <td>В соответствии со ст. 26 Трудового кодекса Республики Беларусь (далее – ТК) и иными
                                    законодательными актами
                                </td>
                                <td>(ст. 26 Трудового кодекса Республики Беларусь (далее – ТК); п. 11 Декрета Президента
                                    Республики Беларусь от 15 мая 2014 г. № 5; иные законодательные акты)
                                </td>
                                <td>Не передаются</td>
                                <td>55 лет (подп. 673.3 п. 673 Перечня типовых документов перечень типовых документов,
                                    образующихся в процессе деятельности государственных органов, иных организаций и
                                    индивидуальных предпринимателей, с указанием сроков хранения, утвержденному
                                    Постановлением Министерства юстиции Республики Беларусь от 24.05.2012 N 140 N 140)
                                    (далее - Перечень
                                </td>
                            </tr>

                            <tr>
                                <td>Формирование, ведение и хранение личных дел и личных карточек работников
                                </td>
                                <td>Отдел кадров</td>
                                <td>Работники, члены их семей</td>
                                <td>В соответствии с Инструкцией о порядке формирования, ведения и хранения личных дел
                                    работников, утвержденной постановлением Комитета по архивам и делопроизводству при
                                    Совете Министров Республики Беларусь от 26 марта 2004 г. № 2
                                </td>
                                <td>абз. 8 ст. 6 Закона</td>
                                <td>Не передаются</td>
                                <td>После увольнения – 55 лет (п. 673, п. 674 Перечня типовых документов перечень
                                    типовых документов,
                                    образующихся в процессе деятельности государственных органов, иных организаций и
                                    индивидуальных предпринимателей, с указанием сроков хранения, утвержденному
                                    Постановлением Министерства юстиции Республики Беларусь от 24.05.2012 N 140 N 140)
                                    (далее - Перечень
                                </td>
                            </tr>

                            <tr>
                                <td>Ведение трудовых книжек
                                </td>
                                <td>Отдел кадров</td>
                                <td>Работники</td>
                                <td>В соответствии с постановлением Министерства труда и социальной защиты Республики
                                    Беларусь от 16 июня 2014 г. № 40 ”О трудовых книжках“
                                </td>
                                <td>абз. 8 ст. 6 Закона</td>
                                <td>Не передаются</td>
                                <td>Трудовые книжки – на срок трудовой деятельности (после увольнения – до
                                    востребования)
                                    (п. 681 Перечня); книги учета движения трудовых
                                </td>
                            </tr>

                            <tr>
                                <td>Командирование</td>
                                <td>Отдел кадров
                                    Бухгалтерия (бухгалтер)
                                </td>
                                <td>Работники</td>
                                <td>Фамилия, собственное имя, отчество
                                    работника, занимаемая должность, пас-
                                    портные данные, сведения о проездных
                                    документах, бронировании гостиниц и
                                    иные сведения, предусмотренные законодательством и (или) необходимые для организации
                                    командировки
                                </td>
                                <td>абз. 8 ст. 6 Закона
                                    (ст. 93 – 95 ТК)
                                </td>
                                <td>Могут передаваться третьим лицам (при
                                    покупке билетов, бронировании гостиницы, в организацию по месту командирования)
                                </td>
                                <td>3 года. при служебных командировках за
                                    границу – 10 лет
                                    (п.п. 697, 700, 701 Перечня)
                                </td>
                            </tr>

                            <tr>
                                <td>Выплата заработной платы</td>
                                <td>Бухгалтерия
                                    (бухгалтер)
                                </td>
                                <td>Работники, члены
                                    их семей
                                </td>
                                <td>Паспортные данные, сведения о трудовой деятельности, о заработной
                                    плате, банковские данные
                                </td>
                                <td>абз. 8 ст. 6 Закона
                                    (п. 4 ч. 1 ст. 55 ТК)
                                </td>
                                <td>Банки, налоговые и
                                    иные государственные органы.
                                </td>
                                <td>п. 204 Перечня - Лицевые счета (расчетные листки) по начислению заработной платы
                                    работникам -55 лет:
                                    <br/> <br/>п. 205 Перечня - Документы по начислению заработной платы работникам
                                    (табели учета
                                    рабочего времени, расчеты, справки и др.) - 3 года. После проведения налоговыми
                                    органами проверки соблюдения налогового законодательства. Если налоговыми органами
                                    проверка соблюдения налогового законодательства не проводилась - 10 лет
                                </td>
                            </tr>

                            <tr>
                                <td>Предоставление гарантий и компенсаций в соответствии с законодательством о труде
                                </td>
                                <td>Отдел кадров
                                    Бухгалтерия
                                    (бухгалтер)
                                </td>
                                <td>Работники, члены их семей</td>
                                <td>Фамилия, собственное имя, отчество работника, занимаемая должность, иные сведения,
                                    послужившие основанием для предоставления гарантии, компенсации
                                </td>
                                <td>абз. 8 ст. 6 Закона (глава 9 ТК)</td>
                                <td>Не передаются</td>
                                <td>В соответствии с Перечнем в зависимости от гарантии (компенсации)</td>
                            </tr>

                            <tr>
                                <td>Предоставление трудовых и социальных отпусков</td>
                                <td>Отдел кадров</td>
                                <td>Работники, члены их семей</td>
                                <td>Фамилия, собственное имя, отчество, занимаемая должность работника, даты
                                    отпуска, вид отпуска, иные сведения, послужившие основанием для предоставления
                                    социального отпуска (сведения о состоянии здоровья, о рождении детей)
                                </td>
                                <td>абз. 8 ст. 6 Закона (глава 12 ТК)</td>
                                <td>Не передаются</td>
                                <td>Графики трудовых отпусков – 1 год
                                    (п. 702 Перечня); <br/><br/>Приказы о предоставлении трудовых отпусков –
                                    3 года (п. 21.4 Перечня) После проведения налоговыми органами проверки соблюдения
                                    налогового законодательства. Если налоговыми органами проверка соблюдения налогового
                                    законодательства не проводилась - 10 лет. Приказы, распоряжения, используемые при
                                    определении размеров социальных пособий (пенсий, выплат в рамках государственных
                                    систем социального страхования и социального обеспечения и др.), - 55 лет.
                                </td>
                            </tr>

                            <tr>
                                <td>Изменение и прекращение трудового договора</td>
                                <td>Отдел кадров</td>
                                <td>Работники</td>
                                <td>Фамилия, собственное имя, отчество работника, сведения о трудовой деятельности, о
                                    семейном положении,
                                    об образовании, объяснительные и докладные записки и иные сведения, послужившие
                                    основанием для изменения, прекращения трудового договора
                                </td>
                                <td>абз. 8 ст. 6 Закона (главы 3, 4, 9 и 12 ТК)</td>
                                <td>Не передаются</td>
                                <td>После увольнения – п. 21.3, 122.3 Перечня -
                                    55 лет
                                </td>
                            </tr>

                            <tr>
                                <td>Ведение воинского учета</td>
                                <td>Отдел кадров</td>
                                <td>Работники – военнообязанные, члены их семей</td>
                                <td>В соответствии с постановлением Министерства обороны Республики Беларусь от 27
                                    января 2020 г. № 5 ”Об установлении форм документов воинского учета“
                                </td>
                                <td>абз. 8 ст. 6 Закона (ст. 9 Закона Республики Беларусь от 5 ноября 1992 г. № 1914-XII
                                    ”О воинской обязанности и воинской службе“)
                                </td>
                                <td>Местные исполнительные и распорядительные органы, военные комиссариаты, органы
                                    государственной безопасности и иные государственные органы, осуществляющие
                                    воинский учет
                                </td>
                                <td>5 лет (п. 691 Перечня)</td>
                            </tr>

                            <tr>
                                <td>Обязательное страхование от несчастных случаев на производстве и профессиональных
                                    заболеваний
                                </td>
                                <td>Бухгалтерия</td>
                                <td>Работники</td>
                                <td>Паспортные данные, сведения о трудовой деятельности</td>
                                <td>абз. 8 ст. 6 Закона (ст. 224 ТК; глава 16 Положения о страховой деятельности в
                                    Республике Беларусь, утвержденного Указом Президента Республики Беларусь от 25
                                    августа 2006 г. № 530)
                                </td>
                                <td>Белгосстрах</td>
                                <td>до 50 лет согласно главе 28 Перечня</td>
                            </tr>

                            <tr>
                                <td>Проведение/организация рекламных игр, публичных конкурсов</td>
                                <td>Ответственное
                                    структурное
                                    подразделение
                                </td>
                                <td>Лица, изъявившие желание принять участие в рекламной игре, публичном конкурсе</td>
                                <td>В отношении участников Рекламной игры/Конкурса: игровой код, фамилия, собственное
                                    имя, отчество (если таковое имеется), адрес электронной почты,
                                    контактный номер телефона - в случае, если Правилами рекламной игры/Конкурса
                                    предусмотрена коммуникация/идентификация ЧРУП «Промофреш» с участниками с
                                    использованием номера телефона, населенный пункт (район, область) места жительства -
                                    в случае, если Правилами рекламной игры/Конкурса предусмотрена
                                    коммуникация/идентификация ЧРУП «Промофреш» с участниками с использованием данных о
                                    месте жительства, номер бонусной карты
                                </td>
                                <td>Согласие на участие в рекламной игре, публичном конкурсе. Обработка персональных
                                    данных является необходимой для выполнения обязанностей (полномочий),
                                    предусмотренных законодательными актами (абзац 20 статьи 6 Закона).
                                    В случаях публикации в СМИ также фамилии, инициалов победителя (победителей), его
                                    выигрыш (их выигрыши – п. 11 Положения о проведении рекламных игр на территории
                                    Республики Беларусь, утвержденного Указом Президента Республики Беларусь от
                                    30.01.2003 N 51.
                                </td>
                                <td>пункт 11 Указа Президента Республики Беларусь от 30.01.2003 № 51 «О проведении
                                    рекламных игр в Республике Беларусь», статья 927 Гражданского кодекса). Согласие на
                                    обработку персональных данных.
                                </td>
                                <td>3 года со дня окончания рекламной игры, публичного конкурса - п. 15 Положения о
                                    проведении рекламных игр на территории Республики Беларусь, утвержденного Указом
                                    Президента Республики Беларусь от 30.01.2003 N 51 "О проведении рекламных игр в
                                    Республике Беларусь"
                                </td>
                            </tr>

                            <tr>
                                <td>Рассмотрение обращений граждан и юридических лиц</td>
                                <td>Ответственное структурное подразделение</td>
                                <td>Граждане, в т.ч. представители юридических лиц</td>
                                <td>Фамилия, собственное имя, отчество, адрес места жительства (места пребывания), суть
                                    обращения, иные сведения, указанные в обращении
                                </td>
                                <td>абз. 20 ст. 6 Закона
                                    (ст. 12 Закона Республики Беларусь от 18 июля 2011 г. № 300-З ”Об обращениях граждан
                                    и юридических лиц“)
                                </td>
                                <td>Не передаются</td>
                                <td>до 10 лет (глава 7 Перечня)</td>
                            </tr>

                            <tr>
                                <td>Заключение и исполнение гражданско-правовых договоров с контрагентами.</td>
                                <td>Ответственное
                                    структурное
                                    подразделение
                                </td>
                                <td>1. Лица, уполномоченные на заключение договора.
                                    2. Работники (представители) контрагента, отвечающие за взаимодействие сторон по
                                    вопросам сотрудничества.
                                </td>
                                <td>1. Фамилия, собственное имя, отчество (при наличии) либо инициалы лица, должность
                                    лица, заключившего договор, серия, номер, дата выдачи паспорта/ВНЖ, орган его
                                    выдавший, личный идентификационный номер; адрес регистрации, адрес места жительства;
                                    номер телефона; адрес электронной почты; контактный номер телефона и адрес
                                    электронной почты.<br/><br/>
                                    2. Фамилия, собственное имя, отчество (при наличии), телефон, электронная почта
                                    лица, отвечающего за взаимодействие сторон по вопросам сотрудничества, контактный
                                    номер телефона и адрес электронной почты. Обработка персональных данных является
                                    необходимой для выполнения обязанностей (полномочий), предусмотренных
                                    законодательными актами.
                                </td>
                                <td>1. В случае заключения договора с физическим лицом – обработка на основании договора
                                    с субъектом персональных данных
                                    (абзац 15 статьи 6 Закона Республики Беларусь от 07.05.2021 № 99-З «О защите
                                    персональных данных»).<br/><br/>
                                    2. В случае заключения договора с юридическим лицом – обработка персональных данных
                                    является необходимой для выполнения обязанностей (полномочий), предусмотренных
                                    законодательными актами (абзац 20 статьи 6 Закона Республики Беларусь от 07.05.2021
                                    № 99-З «О защите персональных данных).
                                </td>
                                <td>Не передаются</td>
                                <td>согласно Перечню - 3 года после окончания срока действия договора, проведения
                                    налоговыми органами проверки соблюдения налогового законодательства. Если налоговыми
                                    органами проверка соблюдения налогового законодательства не проводилась – 10 лет
                                    после окончания срока действия договора.
                                </td>
                            </tr>

                            <tr>
                                <td>Предварительная запись на личный прием/личный прием</td>
                                <td>Ответственное структурное подразделение</td>
                                <td>Лица, обращающиеся на личный прием/посетители</td>
                                <td>Фамилия, собственное имя, отчество (при его наличии), контактный телефон, суть
                                    обращения, иные данные, имеющие значение для рассмотрения обращения по существу
                                </td>
                                <td>Обработка персональных данных является необходимой для выполнения обязанностей
                                    (полномочий), предусмотренных законодательными актами (абзац 20 статьи 6 Закона
                                    Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных», пункт 7
                                    статьи 6 Закона Республики Беларусь от 18.07.2011 № 300-З «Об обращениях граждан и
                                    юридических лиц»).
                                </td>
                                <td>Не передаются</td>
                                <td>1 год с даты последнего обращения (п. 92 Перечня)</td>
                            </tr>

                            <tr>
                                <td>Работа с аффилированными лицами</td>
                                <td>Ответственное структурное подразделение</td>
                                <td>Лица, являющиеся аффилированными согласно ст. 56 Закона Республики Беларусь «О хозяйственных обществах»</td>
                                <td>Фамилия, им, отчество (при наличии), адрес регистрации, номер телефона</td>
                                <td>Республики Беларусь «О хозяйственных обществах»</td>
                                <td>аффилированным лицам по запросу в целях исполнения требований Закона Республики Беларусь «О хозяйственных обществах»</td>
                                <td>5 рабочих дней с момента получения уведомления о том, что лицо утратило статус аффилированного</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>Директор ЧРУП «Промофреш» <br/> <br/> Чепиков Е.И.</p>
                </div>
            </div>
        </div>
        <FooterPublic/>
    </div>);
}