import React from 'react';
import styles from './css/DeclineModal.module.css';

const PrizeModal = ({
                        isOpen,
                        onClose,
                        onConfirm,
                        message,
                        setSelectedPrize,
                        setPrize,
                        prizes,
                        receipt,
                        selectedDate,
                        setSelectedDate,
                        setSelectedCode,
                        selectedCode
                    }) => {

    const raffles = [
        '2024-11-29T12:30:00',
        '2024-12-06T12:30:00',
        '2024-12-13T12:30:00',
        '2024-12-20T12:30:00',
        '2024-12-27T12:30:00',
        '2025-01-03T12:30:00',
        '2025-01-10T12:30:00',
        '2025-01-17T12:30:00',
        '2025-01-17T13:00:00'
    ]

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modal}
                 onClick={(e) => e.stopPropagation()}
            >
                <p>{message}</p>
                <select onChange={(e) => {
                    setSelectedDate(e.target.value);
                }}
                        defaultValue="">
                    <option value="" disabled>Выберите розыгрыш</option>
                    {(raffles ?? []).map((raffle, index) => (
                        <option key={index} value={raffle}>
                            Розыгрыш от {raffle.replace("T", " ")}
                        </option>
                    ))}
                </select>

                <select
                    onChange={(e) => {
                        const selectedPrize = prizes.find(prize => prize.id === Number(e.target.value));
                        setSelectedPrize(selectedPrize.id);
                        setPrize(selectedPrize.name);
                    }}
                    defaultValue="">
                    <option value="" disabled>Выберите приз</option>
                    {(prizes ?? []).map((prize) => (
                        <option key={prize.id} value={prize.id}>
                            {prize.name}
                        </option>
                    ))}
                </select>

                <select
                    onChange={(e) => setSelectedCode(e.target.value)}
                    value={selectedCode}
                    defaultValue="">
                    <option value="" disabled>Выберите код</option>
                    {(receipt?.receiptCodes ?? []).map((code) => (
                        <option key={code.id} value={code.id}>
                            {code.id}
                        </option>
                    ))}
                </select>

                <div className={styles.controls}>
                    <button onClick={onClose}>
                        Отклонить
                    </button>
                    <button onClick={onConfirm}>
                        Принять
                    </button>
                </div>

                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48px"
                    viewBox="0 -960 960 960"
                    width="48px"
                    fill="#fff"
                    style={{
                        position: "absolute",
                        right: "24px",
                        top: "24px",
                        cursor: "pointer"
                    }}
                    onClick={onClose}
                >
                    <path
                        d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
                    />
                </svg>
            </div>
        </div>
    );
};

export default PrizeModal;
